import React, { createContext, useState, useContext, ReactNode } from 'react';

interface StreamContextType {
    nodesInStream: Record<string, any>;
    selectedNodeIds: string[];
    addNode: (key: string, value: any) => void;
    removeNode: (key: string) => void;
    propagateNodeProps: () => void;
    loadStreamFromS3: (pathAndName: string) => void;
    selectNodeIds: (nodeIds: string[]) => void;
}

const defaultStreamContext: StreamContextType = {
    nodesInStream: {},
    selectedNodeIds: [],
    addNode: (key: string, value: any): void => {
        throw new Error("Function 'addNode' not implemented.");
    },
    removeNode: (key: string): void => {
        throw new Error("Function 'removeNode' not implemented.");
    },
    propagateNodeProps: (): void => {
        throw new Error("Function 'propagateNodeProps' not implemented.");
    },
    loadStreamFromS3: (pathAndName: string): void => {
        throw new Error("Function 'loadStreamFromS3' not implemented.");
    },
    selectNodeIds: (nodeIds: string[]): void => {
        throw new Error("Function 'selectNodeIds' not implemented.");
    }
};

const StreamContext = createContext<StreamContextType>(defaultStreamContext);

interface StreamContextProviderProps {
    children: ReactNode;
}

export const StreamContextProvider: React.FC<StreamContextProviderProps> = ({ children }) => {
    const [nodesInStream, setNodesInStream] = useState<Record<string, any>>({});
    const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);

    const addNode = (key: string, value: any) => {
        setNodesInStream(prev => ({ ...prev, [key]: value }));
    };

    const removeNode = (key: string) => {
        setNodesInStream(prev => {
            const newNodes = { ...prev };
            delete newNodes[key];
            return newNodes;
        });
    };

    const propagateNodeProps = () => {
        console.log("propagateNodeProps");
    };

    const loadStreamFromS3 = (pathAndName: string) => {
        console.log("loadStreamFromS3: " + pathAndName);
    };

    const selectNodeIds = (nodeIds: string[]) => {
        setSelectedNodeIds(nodeIds);
    };

    const streamContextValues: StreamContextType = {
        nodesInStream,
        selectedNodeIds,
        addNode,
        removeNode,
        propagateNodeProps,
        loadStreamFromS3,
        selectNodeIds,
    };

    return (
        <StreamContext.Provider value={streamContextValues}>
            {children}
        </StreamContext.Provider>
    );
};

export const useStreamContext = () => {
    return useContext(StreamContext);
};
