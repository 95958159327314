import styled from "@emotion/styled";

export namespace S {
    export const Port = styled.div`
      width: 32px;
      height: 32px;
      z-index: 10;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 32px;
      border-color: white;
      border-style: solid;
      border-width: 4px;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 1);
      }
    `;
}