import * as React from 'react';
import Header from '../common/Header';
import { Box } from '@mui/material';
import { ManagedRoute } from '../../tools/routes';
import Protected from './Protected';
import Footer from '../common/Footer';
import { useCheckMobileScreen } from '../../tools/mobile';

export interface IRouteContentProps {
    route: ManagedRoute
}

const RouteContent: React.FunctionComponent<IRouteContentProps> = (props: IRouteContentProps) => {
    const isMobile = useCheckMobileScreen()
    return (
        <>
            <Header />
            <Box component="div" sx={{
                margin: "auto",
                marginBottom: "5em",
                marginTop: "2em",
                width: isMobile ? "95%" : "97.5%"
            }}>
                {props.route.protected ?
                    <Protected roles={props.route.roles}>
                        {props.route.child}
                    </Protected>
                    : <>{props.route.child}</>
                }
            </Box>
            <Footer />
        </>
    );
}

export default RouteContent;