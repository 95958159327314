import * as React from 'react';
import {WriteNodeModel} from './WriteNodeModel';
import {DiagramEngine, PortModelAlignment, PortWidget} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {S} from '../../port/SimplePortWidget';
import {NodeTypeEnum} from "../NodeTypeEnum";
import DefaultNodeDecoration from "../DefaultNodeDecoration";
import WriteNodeSettings from "./settings/WriteNodeSettings";

export interface WriteNodeWidgetProps {
    node: WriteNodeModel;
    engine: DiagramEngine;
    width: number;
    height: number;
}

const WriteNodeWidget: React.FC<WriteNodeWidgetProps> = (props: WriteNodeWidgetProps) => {

    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [highlighted, setHighlighted] = React.useState<boolean>(false);
    const [comment, setComment] = React.useState<string>("");
    const [settingsSummary, setSettingsSummary] = React.useState<string>("🠔 👓");

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    React.useEffect(() => {
        if (props.node.isSelected()) {
            setHighlighted(true)
        } else {
            setHighlighted(false)
        }
        // eslint-disable-next-line
    }, [props.node.isSelected()])


    return (
        <div
            className={'diamond-node'}
            style={{
                position: 'relative',
                width: props.width,
                height: props.height
            }}
        >
            <svg width="304" height="180" xmlns="http://www.w3.org/2000/svg"
                 xmlnsXlink="http://www.w3.org/1999/xlink" overflow="hidden">
                <defs>
                    <filter id="fx0-nodeWrite" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx1-nodeWrite" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx2-nodeWrite" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx3-nodeWrite" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx4-nodeWrite" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx5-nodeWrite" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx6-nodeWrite" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx7-nodeWrite" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx8-nodeWrite" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <clipPath id="clip9-nodeWrite">
                        <rect x="897" y="479" width="304" height="180"/>
                    </clipPath>
                    <clipPath id="clip10-nodeWrite">
                        <path
                            d="M930.471 487C915.852 487 904 498.865 904 513.5L904 619.5C904 634.135 915.852 646 930.471 646L1079 646 1079 487ZM897 479 1201 479 1201 659 897 659Z"
                            fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip11-nodeWrite">
                        <path d="M1093 487 1093 646 1118 646 1118 487ZM897 479 1201 479 1201 659 897 659Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip12-nodeWrite">
                        <path d="M1132 486 1132 513 1157 513 1157 486ZM897 479 1201 479 1201 659 897 659Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip13-nodeWrite">
                        <path d="M1159 599 1159 627 1184 627 1184 599ZM897 479 1201 479 1201 659 897 659Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip14-nodeWrite">
                        <path d="M1135 619 1135 646 1159 646 1159 619ZM897 479 1201 479 1201 659 897 659Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip15-nodeWrite">
                        <path d="M1163 502 1163 529 1188 529 1188 502ZM897 479 1201 479 1201 659 897 659Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip16-nodeWrite">
                        <path d="M1161 554 1161 582 1186 582 1186 554ZM897 479 1201 479 1201 659 897 659Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip17-nodeWrite">
                        <path d="M1136 524 1136 552 1161 552 1161 524ZM897 479 1201 479 1201 659 897 659Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip18-nodeWrite">
                        <path d="M1129 573 1129 601 1154 601 1154 573ZM897 479 1201 479 1201 659 897 659Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                </defs>
                <g clipPath="url(#clip9-nodeWrite)" transform="translate(-897 -479)">
                    <g clipPath="url(#clip10-nodeWrite)">
                        <g filter="url(#fx0-nodeWrite)" transform="translate(896 479)">
                            <g>
                                <path
                                    d="M0 0 148.529 0C163.148 0 175 11.8647 175 26.5005L175 132.5C175 147.135 163.148 159 148.529 159L0 159Z"
                                    stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8"
                                    fill="#E3EDFF"
                                    fillRule="evenodd" transform="matrix(-1 0 0 1 185.828 10.8284)"/>
                            </g>
                        </g>
                    </g>
                    <path
                        d="M0 0 148.529 0C163.148 0 175 11.8647 175 26.5005L175 132.5C175 147.135 163.148 159 148.529 159L0 159Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8" fill="#E3EDFF"
                        fillRule="evenodd"
                        transform="matrix(-1 0 0 1 1079 487)"/>
                    <g clipPath="url(#clip11-nodeWrite)">
                        <g filter="url(#fx1-nodeWrite)" transform="translate(1085 479)">
                            <g>
                                <rect x="0" y="0" width="25" height="159" stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8"
                                      strokeMiterlimit="8" fill="#E3EDFF"
                                      transform="matrix(-1 0 0 1 35.8284 10.8284)"/>
                            </g>
                        </g>
                    </g>
                    <rect x="0" y="0" width="25" height="159" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8"
                          strokeMiterlimit="8" fill="#E3EDFF" transform="matrix(-1 0 0 1 1118 487)"/>
                    <g clipPath="url(#clip12-nodeWrite)">
                        <g filter="url(#fx2-nodeWrite)" transform="translate(1124 478)">
                            <g>
                                <rect x="0" y="0" width="25" height="27" stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8"
                                      strokeMiterlimit="8" fill="#E3EDFF"
                                      transform="matrix(-1 0 0 1 35.8284 10.8284)"/>
                            </g>
                        </g>
                    </g>
                    <rect x="0" y="0" width="25" height="27" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8" strokeMiterlimit="8"
                          fill="#E3EDFF" transform="matrix(-1 0 0 1 1157 486)"/>
                    <g clipPath="url(#clip13-nodeWrite)">
                        <g filter="url(#fx3-nodeWrite)" transform="translate(1151 591)">
                            <g>
                                <rect x="0" y="0" width="25" height="28" stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8"
                                      strokeMiterlimit="8" fill="#E3EDFF"
                                      transform="matrix(-1 0 0 1 35.8284 10.8284)"/>
                            </g>
                        </g>
                    </g>
                    <rect x="0" y="0" width="25" height="28" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8" strokeMiterlimit="8"
                          fill="#E3EDFF" transform="matrix(-1 0 0 1 1184 599)"/>
                    <g clipPath="url(#clip14-nodeWrite)">
                        <g filter="url(#fx4-nodeWrite)" transform="translate(1127 611)">
                            <g>
                                <rect x="0" y="0" width="24" height="27" stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8"
                                      strokeMiterlimit="8" fill="#E3EDFF"
                                      transform="matrix(-1 0 0 1 34.8284 10.8284)"/>
                            </g>
                        </g>
                    </g>
                    <rect x="0" y="0" width="24" height="27" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8" strokeMiterlimit="8"
                          fill="#E3EDFF" transform="matrix(-1 0 0 1 1159 619)"/>
                    <g clipPath="url(#clip15-nodeWrite)">
                        <g filter="url(#fx5-nodeWrite)" transform="translate(1155 494)">
                            <g>
                                <rect x="0" y="0" width="25" height="27" stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8"
                                      strokeMiterlimit="8" fill="#E3EDFF"
                                      transform="matrix(-1 0 0 1 35.8284 10.8284)"/>
                            </g>
                        </g>
                    </g>
                    <rect x="0" y="0" width="25" height="27" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8" strokeMiterlimit="8"
                          fill="#E3EDFF" transform="matrix(-1 0 0 1 1188 502)"/>
                    <g clipPath="url(#clip16-nodeWrite)">
                        <g filter="url(#fx6-nodeWrite)" transform="translate(1153 546)">
                            <g>
                                <rect x="0" y="0" width="25" height="28" stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8"
                                      strokeMiterlimit="8" fill="#E3EDFF"
                                      transform="matrix(-1 0 0 1 35.8284 10.8284)"/>
                            </g>
                        </g>
                    </g>
                    <rect x="0" y="0" width="25" height="28" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8" strokeMiterlimit="8"
                          fill="#E3EDFF" transform="matrix(-1 0 0 1 1186 554)"/>
                    <g clipPath="url(#clip17-nodeWrite)">
                        <g filter="url(#fx7-nodeWrite)" transform="translate(1128 516)">
                            <g>
                                <rect x="0" y="0" width="25" height="28" stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8"
                                      strokeMiterlimit="8" fill="#E3EDFF"
                                      transform="matrix(-1 0 0 1 35.8284 10.8284)"/>
                            </g>
                        </g>
                    </g>
                    <rect x="0" y="0" width="25" height="28" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8" strokeMiterlimit="8"
                          fill="#E3EDFF" transform="matrix(-1 0 0 1 1161 524)"/>
                    <g clipPath="url(#clip18-nodeWrite)">
                        <g filter="url(#fx8-nodeWrite)" transform="translate(1121 565)">
                            <g>
                                <rect x="0" y="0" width="25" height="28" stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8"
                                      strokeMiterlimit="8" fill="#E3EDFF"
                                      transform="matrix(-1 0 0 1 35.8284 10.8284)"/>
                            </g>
                        </g>
                    </g>
                    <rect x="0" y="0" width="25" height="28" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8" strokeMiterlimit="8"
                          fill="#E3EDFF" transform="matrix(-1 0 0 1 1154 573)"/>
                </g>
            </svg>

            <DefaultNodeDecoration
                nodeType={NodeTypeEnum.NODE_WRITE}
                comment={comment}
                textOnNode={settingsSummary}
                onDoubleClick={handleOpen}
            />

            <PortWidget
                style={{
                    top: props.height / 2 - 22,
                    left: -25,
                    position: 'absolute'
                }}
                port={props.node.getPort('input') || new SparkyELTPortModel(PortModelAlignment.LEFT, 'input')}
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>

            <WriteNodeSettings open={modalOpen}
                              handleClose={handleClose}
                              nodeModel={props.node}
                              selectedConnectionId={props.node.getSelectedConnectionId()}
                              comment={comment}
                              setComment={setComment}
                              setSettingsSummary={setSettingsSummary}
            />

        </div>
    );
}

export default WriteNodeWidget