import { Grid, Container, Typography, Button, Stack, Alert } from '@mui/material';
import * as React from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useEffect, useMemo, useState } from 'react';
import { useAuthApiClient } from '../../clients/AuthApiClient';
import { ErrorResponse, ErrorType } from '../../clients/error/ErrorResponse';
import { useTranslation } from 'react-i18next';
import { User } from '../../clients/model/User';
import InlineLoader from '../../components/common/InlineLoader';
import PasswordInput from '../../components/inputs/PasswordInput';
import { validatePassword, validateRepeatPassword } from '../../tools/validationTools';
import Headline from '../../components/common/Headline';

export interface IResetPasswordProps {
}

const ResetPassword: React.FunctionComponent<IResetPasswordProps> = (props: IResetPasswordProps) => {

    const navigate = useNavigate()

    const { t } = useTranslation()

    const authClient = useAuthApiClient({ preventNotification: true })

    const [resetButtonLoading, setResetButtonLoading] = useState(false)

    const [user, setUser] = useState<User>()

    const [pageLoaded, setPageLoaded] = useState(false)
    const [pageError, setPageError] = useState<string>()

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<string>();
    const [repeatPasswordError, setRepeatPasswordError] = useState<string>();
    const [repeatPassword, setRepeatPassword] = useState('');

    const [passwordResetted, setPasswordResetted] = useState(false)

    const { processId } = useParams()

    const passwordValid = useMemo(() => {
        const validation = validatePassword(password)
        setPasswordError(validation.error)
        return validation.valid
    }, [password])

    const repeatPasswordValid = useMemo(() => {
        const validation = validateRepeatPassword(password, repeatPassword)
        setRepeatPasswordError(validation.error)
        return validation.valid
    }, [password, repeatPassword])

    const handlePasswordReset = () => {
        if (passwordValid && repeatPasswordValid && processId) {
            setResetButtonLoading(true)
            authClient.resetPassword(processId, password)
                .then(() => setPasswordResetted(true))
                .catch((err: ErrorResponse) => setPageError(err.message))
                .finally(() => setResetButtonLoading(false))
        }
    }

    useEffect(() => {
        if (processId) {
            if (!pageLoaded) {
                authClient.getPasswordResetProcess(processId)
                    .then(setUser)
                    .catch((err: ErrorResponse) => {
                        switch (err.type) {
                            case ErrorType.ENTITY_NOT_FOUND:
                                setPageError(t('login.resetLinkNotValid'))
                                break;
                            default:
                                setPageError(t('common.somethingWentWrong'))
                        }
                    })
                    .finally(() => setPageLoaded(true))
            }
        } else {
            navigate("/reset-password")
        }
    }, [authClient, navigate, pageLoaded, processId, t])

    if (passwordResetted) {
        return (
            <>
                <Grid container justifyContent="center">
                    <Container >
                        <Grid container spacing={3} sx={{ marginTop: "5vh", paddingLeft: "15%", paddingRight: "15%" }}>
                            <Grid item xs={12}>
                                <Headline showLogo title={t('login.passwordResetted')} />
                            </Grid>
                            <Grid item xs={12} container justifyContent='center'>
                                <Typography variant="h5" align="center">
                                    {t('login.passwordSuccessfullyResettedLoginAgain')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} spacing={2} container justifyContent='center'>
                                <Grid item>
                                    <Button sx={{ width: '200px', fontSize: '16px' }} variant="contained" component={NavLink} to="/login">
                                        {t('login.navToLogin')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container >
                </Grid >
            </>
        );
    }

    return (
        <>
            <Grid container justifyContent="center">
                <Container >
                    <Grid container spacing={3} sx={{ marginTop: "5vh", paddingLeft: "15%", paddingRight: "15%" }}
                        onKeyDownCapture={(e) => {
                            if (e.key === 'Enter') {
                                handlePasswordReset()
                            }
                        }}>
                        <Grid item xs={12}>
                            <Headline showLogo title={t('login.resetPassword')} />
                        </Grid>
                        {pageError &&
                            <Grid item xs={12} container justifyContent='center'>
                                <Alert severity="error">
                                    {pageError}
                                </Alert>
                            </Grid>
                        }
                        {user &&
                            <>
                                <Grid item xs={12} container justifyContent='center'>
                                    <Typography variant="h5" align="center">
                                        {t('login.helloPleaseChooseNewPassword', { username: user.username })}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} container justifyContent='center'>
                                    <Stack spacing={2} sx={{ width: "100%" }}>
                                        <PasswordInput
                                            error={!!passwordError}
                                            errortext={passwordError}
                                            title={t('login.newPassword')}
                                            sx={{ width: '100%' }}
                                            password={password}
                                            onPasswordChange={setPassword}
                                            onSubmit={() => handlePasswordReset()}
                                        />
                                        <PasswordInput
                                            error={!!repeatPasswordError}
                                            errortext={repeatPasswordError}
                                            title={t('login.newPasswordRepeat')}
                                            sx={{ width: '100%' }}
                                            password={repeatPassword}
                                            onPasswordChange={setRepeatPassword}
                                            onSubmit={() => handlePasswordReset()}
                                        />
                                    </Stack>
                                </Grid>
                            </>
                        }
                        {!pageLoaded &&
                            <Grid item xs={12}>
                                <InlineLoader size='medium' />
                            </Grid>
                        }
                        <Grid item xs={12} spacing={2} container justifyContent='center'>
                            <Grid item>
                                <Button sx={{ width: '200px', fontSize: '16px' }} variant="outlined" component={NavLink} to="/login">
                                    {t('login.navToLogin')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    sx={{ width: '200px', fontSize: '16px' }}
                                    disabled={!(passwordValid && repeatPasswordValid)}
                                    onClick={handlePasswordReset}
                                    loading={resetButtonLoading}
                                >
                                    {t('login.reset')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    );
}

export default ResetPassword;