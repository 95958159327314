import * as React from 'react';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from "react-i18next";
import Flag from 'react-world-flags'

export interface ILanguageSwitcherProps {

}

const LanguageSwitcher: React.FunctionComponent<ILanguageSwitcherProps> = (props: ILanguageSwitcherProps) => {

    // const theme = useTheme();
    const { i18n } = useTranslation();
    const changeLang = (lang: string) => {
        i18n.changeLanguage(lang)
    }

    return (
        <>
            <Select
                size='small'
                value={i18n.language}
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, paddingTop: "0.4em" }}
                inputProps={{ IconComponent: () => null, sx: { padding: '0.25em !important' } }}
                onChange={e => changeLang(e.target.value)}
            >
                <MenuItem value={"de-DE"}><Flag code='de' height={10} /> </MenuItem>
                <MenuItem value={"en-US"}><Flag code='us' height={10} /> </MenuItem>
            </Select>
        </>
    )
};

export default LanguageSwitcher;
