
export enum ClusterRunningState {
    STARTING = "STARTING",
    WAITING = "WAITING",
    TERMINATING = "TERMINATING",
    TERMINATED = "TERMINATED",
}

export interface ClusterStatus {
    state: string
}
export interface ClusterSummary {
    id: string
    name: string
    status: ClusterStatus
}