import {ThemeProvider, createTheme} from '@mui/material';
import * as React from 'react';

// import i18n (needs to be bundled ;))
import './tools/i18n/I18n';

import {UserContextProvider} from './contexts/UserContext';
import NotificationContextProvider from './contexts/NotificationContext';
import Root from './Root';

const theme = createTheme({
    palette: {
        primary: {
            main: "#DF7C40"
        },
        secondary: {
            main: "#D1D3D5"
        },
        background: {
            paper: "#F2F0EE"
        }
    }
});

function App() {

    return (
        <>
            <ThemeProvider theme={theme}>
                <NotificationContextProvider>
                    <UserContextProvider>
                        <Root/>
                    </UserContextProvider>
                </NotificationContextProvider>
            </ThemeProvider>
        </>
    );
}

export default App;
