import React, {ReactNode, useState} from "react";
import {Tooltip} from "@mui/material";
import {IoGlassesOutline} from "react-icons/io5";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDial from "@mui/material/SpeedDial";


export interface ToolboxNode {
    icon: ReactNode;
    name: string;
}

export interface NodeToolboxSpeedDialProps {
    toolboxNodes: ToolboxNode[]
    handleNewNodeDragStart: (event: React.DragEvent<HTMLDivElement>, action: string) => void
    bgcolor: string
    posBottom: number
    posRight: number
}

const NodeToolboxSpeedDial: React.FC<NodeToolboxSpeedDialProps> = (props) => {

    const [selectedIcon, setSelectedIcon] = useState<React.ReactNode>(<IoGlassesOutline style={{ fontSize: 35 }} />);
    const [selectedNodeName, setSelectedNodeName] = useState<string>("");

    React.useEffect(() => {
        if (props.toolboxNodes.length > 0) {
            setSelectedIcon(props.toolboxNodes[0].icon);
            setSelectedNodeName(props.toolboxNodes[0].name);
        }
    }, [props.toolboxNodes]);

    const handleActionClick = (icon: React.ReactNode, nodeName: string) => {
        setSelectedIcon(icon);
        setSelectedNodeName(nodeName);
    };

    const renderIcon = (icon: React.ReactNode, fontSize: number) => {
        return React.cloneElement(icon as React.ReactElement<any>, { style: { fontSize } });
    };

    return (
        <Tooltip title={selectedNodeName} placement="left">
            <SpeedDial
                ariaLabel="SpeedDial_read"
                FabProps={{
                    sx: {
                        bgcolor: props.bgcolor,
                        '&:hover': {
                            bgcolor: props.bgcolor,
                        },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }
                }}
                sx={{ position: 'absolute', bottom: props.posBottom, right: props.posRight, display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                icon={
                    <div draggable
                         onDragStart={(e) => props.handleNewNodeDragStart(e, selectedNodeName)}
                         style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        {renderIcon(selectedIcon, 35)}
                    </div>
                }
            >
                {props.toolboxNodes.map((node: ToolboxNode) => (
                    <SpeedDialAction
                        key={node.name}
                        icon={<div onClick={() => handleActionClick(node.icon, node.name)}>{node.icon}</div>}
                        tooltipTitle={node.name}
                        sx={{ fontSize: 30 }}
                    />
                ))}
            </SpeedDial>
        </Tooltip>
    );
};

export default NodeToolboxSpeedDial;
