import { NodeModel, NodeModelGenerics, PortModelAlignment } from '@projectstorm/react-diagrams';
import { SparkyELTPortModel } from '../../port/SparkyELTPortModel';

export interface DiamondNodeModelGenerics {
    PORT: SparkyELTPortModel;
}

export class RenameColsNodeModel extends NodeModel<NodeModelGenerics & DiamondNodeModelGenerics> {
    constructor() {
        super({
            type: 'Node_RENAME_COLS'
        });
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'input'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output'));
    }
}