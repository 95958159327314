import { Role } from "../clients/model/Role"
import Login from "../pages/auth/Login"
import RequestPasswordReset from "../pages/auth/RequestPasswordReset"
import ResetPassword from "../pages/auth/ResetPassword"
import Signup from "../pages/auth/Signup"
import DataExplorer from "../pages/files/DataExplorer"
import Dashboard from "../pages/home/Dashboard"
import Demo from "../pages/home/Demo"
import Home from "../pages/home/Home"
import StreamExplorer from "../pages/home/StreamExporer";

export interface ManagedRoute {
    path: string
    name?: string,
    child: JSX.Element
    protected?: boolean
    roles?: Role[]
}

export const routes: (ManagedRoute)[] = [
    {
        path: "/",
        name: "Home",
        child: <Home />,
    },
    {
        path: "/demo",
        name: "Demo",
        child: <Demo />,
        protected: true
    },
    {
        path: "/login",
        name: "Login",
        child: <Login />,
    },
    {
        path: "/register",
        name: "Register",
        child: <Signup />,
    },
    // {
    //     path: "/profile",
    //     name: "Profile",
    //     child: <Profile />,
    //     protected: true,
    // },
    {
        path: "/reset-password",
        name: "Reset Password",
        child: <RequestPasswordReset />,
    },
    {
        path: "/reset-password/:processId",
        name: "Reset Password",
        child: <ResetPassword />,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        child: <Dashboard />,
        protected: true
    },
    {
        path: "/streams",
        name: "Streams",
        child: <StreamExplorer />,
        protected: true
    },
    {
        path: "/data",
        name: "Data",
        child: <DataExplorer />,
        protected: true
    },
]
