import { PlayArrow } from '@mui/icons-material';
import { Fab } from '@mui/material';
import * as React from 'react';
import { useClusterContext } from '../../../contexts/ClusterContext';
import { ClusterRunningState } from '../../../clients/model/Cluster';
import DisabledToolTip from '../../../components/common/DisabledToolTip';

export interface IStartStreamProps {
}

const StartStream: React.FunctionComponent<IStartStreamProps> = (props: IStartStreamProps) => {
    const clusterCtx = useClusterContext()

    const start = () => {
        if (clusterCtx.clusterId) {
            clusterCtx.startStream()
        }
    }

    const canExecute = clusterCtx.clusterId && clusterCtx.clusterStatus?.state === ClusterRunningState.WAITING
    return (
        <>

            <DisabledToolTip
                disabled={!canExecute}
                tooltip="Cluster is not ready"
            >
                <Fab disabled={!canExecute} onClick={start} color="success"
                     sx={{ position: 'absolute', bottom: "10px", right: "50%", left: "50%", display: 'flex',
                         alignItems: 'center', justifyContent: 'center',
                         width: '100px',    // Increase the width
                         height: '100px',   // Increase the height
                     }}
                >
                    <PlayArrow style={{ fontSize: 50 }}/>
                </Fab>
            </DisabledToolTip>

        </>
    );
}

export default StartStream;