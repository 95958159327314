import { Container, Grid } from '@mui/material';
import * as React from 'react';
import { withAiAnimation } from '../../shared/AiAnimation';
import Explorer from "../../components/common/Explorer";
import { useNavigate } from 'react-router-dom';

export interface IStreamExplorerProps {
}

const StreamExplorer: React.FunctionComponent<IStreamExplorerProps> = (props: IStreamExplorerProps) => {
    const navigate = useNavigate()

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" direction="row" sx={{
                alignItems: "center",
                height: "75vh"
            }}>
                <Container>
                    <Explorer data={[
                        {
                            name: "Berlin", path: "/Berlin", type: "folder", children: [
                                { id: "100", name: "Provisionen", type: "stream" },
                                { id: "101", name: "Außeneinsätze", type: "stream" }
                            ]
                        },
                        {
                            name: "Hamburg", path: "/Hamburg", type: "folder", children: [
                                { id: "102", name: "Provisionen", type: "stream" },
                                { id: "103", name: "Außeneinsätze", type: "stream" }
                            ]
                        },
                        {
                            name: "München", path: "/München", type: "folder", children: [
                                { id: "104", name: "Provisionen", type: "stream" },
                                { id: "105", name: "Außeneinsätze", type: "stream" }
                            ]
                        },
                        { id: "4", name: "Demo-Case", type: "stream" }
                    ]}
                        onStreamClick={() => navigate("/demo")}
                    />
                </Container>
            </Grid>
        </>)
        ;
}

export default withAiAnimation(StreamExplorer);