import { ReadNodeModel } from './ReadNodeModel';
import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import {GenerateModelEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory";
import {GenerateWidgetEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractReactFactory";
import ReadNodeWidget from "./ReadNodeWidget";

export class ReadNodeFactory extends AbstractReactFactory<ReadNodeModel, DiagramEngine> {
    constructor() {
        super('Node_READ');
    }

    generateReactWidget(event: GenerateWidgetEvent<any>): JSX.Element {
        return <ReadNodeWidget engine={this.engine} width={303} height={181} node={event.model}/>;
    }

    generateModel(event: GenerateModelEvent) {
        return new ReadNodeModel("", "");
    }
}