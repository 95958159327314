import {Button, Grid} from '@mui/material';
import * as React from 'react';
import {Link} from 'react-router-dom'
import { withAiAnimation } from '../../shared/AiAnimation';
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import { GrSchedulePlay } from "react-icons/gr";
import { SiDatabricks } from "react-icons/si";
import { GiMatchHead } from "react-icons/gi";
import { GiSecretBook } from "react-icons/gi";
import WhatsHot from "@mui/icons-material/Whatshot";

export interface IDashboardProps {
}

const Dashboard: React.FunctionComponent<IDashboardProps> = (props: IDashboardProps) => {

    const theme = useTheme();
    const { t } = useTranslation();
    // const userCtx = useUserContext()

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" direction="row" sx={{
                alignItems: "center",
                height: "75vh"
            }}>

                <>
                    <Grid item xs={12} sm={6} md={6} lg={6} container justifyContent="center">
                        <Button
                            variant="outlined"
                            size="large"
                            component={Link}
                            to="/streams"
                            sx={{ padding: "1em", fontSize: "16pt", borderRadius: "220px",
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                backdropFilter: 'blur(3px)',
                                boxShadow: '8px 8px 15px rgba(0, 0, 0, 0.2)',
                                border: '1px solid rgba(255, 255, 255, 0.2)',
                                width: '220px'
                            }}
                        >
                            <Grid container justifyContent="center" alignItems="center" textAlign="center" spacing={2}>
                                <Grid item xs={12}>
                                    <GiMatchHead style={{ fontSize: 80, color: theme.palette.primary.main }} height="180px" />
                                </Grid>
                                <Grid item xs={12} sx={{ color: theme.palette.primary.main}}>
                                    {t('dashboard.newStream')}
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} container justifyContent="center">
                        <Button
                            variant="outlined"
                            size="large"
                            component={Link}
                            to="/streams"
                            sx={{ padding: "1em", fontSize: "16pt", borderRadius: "220px",
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                backdropFilter: 'blur(3px)',
                                boxShadow: '8px 8px 15px rgba(0, 0, 0, 0.2)',
                                border: '1px solid rgba(255, 255, 255, 0.2)',
                                width: '220px'
                            }}
                        >
                            <Grid container justifyContent="center" alignItems="center" textAlign="center" spacing={2}>
                                <Grid item xs={12}>
                                    <WhatsHot sx={{ fontSize: 100, color: theme.palette.primary.main }} height="180px" />
                                </Grid>
                                <Grid item xs={12} sx={{ color: theme.palette.primary.main}}>
                                    {t('dashboard.myStreams')}
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3} container justifyContent="center">
                        <Button
                            variant="outlined"
                            size="large"
                            component={Link}
                            to="/data"
                            sx={{ padding: "1em", fontSize: "16pt", borderRadius: "220px",
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                backdropFilter: 'blur(3px)',
                                boxShadow: '8px 8px 15px rgba(0, 0, 0, 0.2)',
                                border: '1px solid rgba(255, 255, 255, 0.2)',
                                width: '220px'
                            }}
                        >
                            <Grid container justifyContent="center" alignItems="center" textAlign="center" spacing={2}>
                                <Grid item xs={12}>
                                    <SiDatabricks style={{ fontSize: 100, color: theme.palette.primary.main }} height="180px" />
                                </Grid>
                                <Grid item xs={12} sx={{ color: theme.palette.primary.main}}>
                                    {t('dashboard.myData')}
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3} container justifyContent="center">
                        <Button
                            variant="outlined"
                            size="large"
                            component={Link}
                            to="/schedules"
                            sx={{ padding: "1em", fontSize: "16pt", borderRadius: "220px",
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                backdropFilter: 'blur(3px)',
                                boxShadow: '8px 8px 15px rgba(0, 0, 0, 0.2)',
                                border: '1px solid rgba(255, 255, 255, 0.2)',
                                width: '220px'
                            }}
                        >
                            <Grid container justifyContent="center" alignItems="center" textAlign="center" spacing={2}>
                                <Grid item xs={12}>
                                    <GrSchedulePlay style={{ fontSize: 90, color: theme.palette.primary.main }} height="180px" />
                                </Grid>
                                <Grid item xs={12} sx={{ color: theme.palette.primary.main}}>
                                    {t('dashboard.mySchedule')}
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3} container justifyContent="center">
                        <Button
                            variant="outlined"
                            size="large"
                            component={Link}
                            to="/schedules"
                            sx={{ padding: "1em", fontSize: "16pt", borderRadius: "220px",
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                backdropFilter: 'blur(3px)',
                                boxShadow: '8px 8px 15px rgba(0, 0, 0, 0.2)',
                                border: '1px solid rgba(255, 255, 255, 0.2)',
                                width: '220px'
                            }}
                        >
                            <Grid container justifyContent="center" alignItems="center" textAlign="center" spacing={2}>
                                <Grid item xs={12}>
                                    <GiSecretBook style={{ fontSize: 80, color: theme.palette.primary.main }} height="180px" />
                                </Grid>
                                <Grid item xs={12} sx={{ color: theme.palette.primary.main}}>
                                    {t('dashboard.mySecrets')}
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                </>
            </Grid>
        </>)
        ;
}

export default withAiAnimation(Dashboard);