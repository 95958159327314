import { NodeModel, NodeModelGenerics, PortModelAlignment } from '@projectstorm/react-diagrams';
import { SparkyELTPortModel } from '../../port/SparkyELTPortModel';

export interface DiamondNodeModelGenerics {
    PORT: SparkyELTPortModel;
}

export class AppendNodeModel extends NodeModel<NodeModelGenerics & DiamondNodeModelGenerics> {
    constructor() {
        super({
            type: 'Node_APPEND'
        });
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputTop'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputBottom'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output'));
    }
}