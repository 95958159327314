import { Typography } from "@mui/material";
import React from "react";

interface CustomHeaderCellProps {
    mainText: string;
    subText: string;
}
//  color="textSecondary"
const CustomHeaderCell = (props: CustomHeaderCellProps) => (
    <div>
        <Typography variant="body1">{props.mainText}</Typography>
        <Typography variant="body2" color="primary" style={{ fontSize: 'smaller' }}>
            {props.subText}
        </Typography>
    </div>
);

export default CustomHeaderCell