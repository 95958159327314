import React, { useState } from "react";
import {
    Grid,
    Typography,
    Button,
    TextField,
    Container,
    Alert, Box, IconButton, Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate, NavLink, useSearchParams } from "react-router-dom";
import { useAuthApiClient } from "../../clients/AuthApiClient";
import { useUserApiClient } from "../../clients/UserApiClient";
import { ErrorResponse } from '../../clients/error/ErrorResponse';
import { useTranslation } from 'react-i18next';
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { BsFacebook } from "react-icons/bs";
import { AiOutlineApple, AiOutlineGithub, AiOutlineInstagram } from "react-icons/ai";
import { useTheme } from "@mui/material/styles";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useUserContext } from "../../contexts/UserContext";
import PasswordInput from "../../components/inputs/PasswordInput";
import { jwtDecode } from "jwt-decode";
import Headline from "../../components/common/Headline";
import {withAiAnimation} from "../../shared/AiAnimation";

const Login = () => {
    const { t } = useTranslation()
    const theme = useTheme();

    const authApiClient = useAuthApiClient({ preventNotification: true });
    const userClient = useUserApiClient({ preventNotification: true });
    const userCtx = useUserContext();

    const [loginError, setLoginError] = useState<string>();

    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [params] = useSearchParams();
    const redirect = params.get("redirect"); //"[...]/login?redirect=/fooBarPage" gives redirect='/fooBarPage'
    const [fbLoginStarted, setFbLoginStarted] = useState<boolean>(false);
    const [fBAccessToken, setFBAccessToken] = useState<string>('');
    const [fbUserId, setFBuserId] = useState<string>('');
    const [fBemail, setFBemail] = useState<string>('');
    const [fBPictureURL, setFBPictureURL] = useState<string>('');

    // handleRegisterFacebook: signup as soon as both information are available
    React.useEffect(() => {
        if (!fbLoginStarted && fBAccessToken.length > 0 && fBemail.length > 0) {
            console.log(fbUserId)
            setFbLoginStarted(true)
            setLoading(true)
            userCtx.loginFacebook(fBAccessToken, fBPictureURL)
            authApiClient
                .isAuthorized() // issued a backend-call with the jwt-token and the credential provider. This throws an error if user is not registered yet
                .then(() => userClient.getCurrentUser())
                // .then(() => navigate('/dashboard'))
                .then(userCtx.setCurrentUser)
                .catch((err: ErrorResponse) => setLoginError("User not found. Please _SIGNUP_"))
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line
    }, [fBAccessToken, fBemail])

    const handleLogin = () => {
        setLoading(true);
        authApiClient
            .signin(username, password)
            .then(userCtx.login)
            .then(() => userClient.getCurrentUser())
            .then(userCtx.setCurrentUser)
            .catch((err: ErrorResponse) => setLoginError(err.message))
            .finally(() => setLoading(false));
    };

    if (userCtx.isLoggedIn()) {
        return (
            <>
                <Navigate to={redirect ?? "/dashboard"} />
            </>
        );
    }

    const handleLoginGoogle = (credentialResponse: CredentialResponse) => {

        setLoading(true);
        // this is a google ID-Token in JWT-format
        const google_access_token = credentialResponse.credential || ""
        const decoded: any = jwtDecode(google_access_token)
        // const userGoogleEmail: string = decoded.email
        const userGooglePicture: string = decoded.picture
        // const userGoogleName: string = decoded.name || ""

        userCtx.loginGoogle(google_access_token, userGooglePicture) // sets the google jwt token and the credential provider as cookie
        authApiClient
            .isAuthorized() // issued a backend-call with the jwt-token and the credential provider. This throws an error if user is not registered yet
            .then(() => userClient.getCurrentUser())
            // .then(() => navigate('/dashboard'))
            .then(userCtx.setCurrentUser)
            .catch((err: ErrorResponse) => setLoginError("User not found. Please _SIGNUP_"))
            .finally(() => setLoading(false));
    }


    return (
        <>
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent white
                    borderRadius: '10px', // Rounded corners
                    backdropFilter: 'blur(3px)', // Blur effect
                    boxShadow: '8px 8px 15px rgba(0, 0, 0, 0.2)', // Shadow towards bottom right
                    width: '90%', // Responsive width adjustments
                    maxWidth: '1000px', // Limits the maximum width to 1000px
                    border: '1px solid rgba(255, 255, 255, 0.2)', // Optional: adds a subtle border
                    margin: 'auto' // Centers the container
                }}
            >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh', // Full viewport height
                }}
            >
                <Container>
                    <Grid
                        container
                        spacing={3}
                        sx={{
                            paddingLeft: "15%",
                            paddingRight: "15%",
                        }}
                        onKeyDownCapture={(e) => {
                            if (e.key === "Enter") {
                                handleLogin();
                            }
                        }}
                    >

                        <Headline showLogo title={t('login.login')} />
                        {loginError && (
                            <Grid
                                item
                                xs={12}
                                container
                                justifyContent="center"
                            >
                                {(loginError.indexOf('_SIGNUP_') > 0 ?
                                    <Box sx={{ color: theme.palette.primary.main }}>
                                        {t('login.userNotFound') + " "}
                                        <Button sx={{ width: '200px', fontSize: '16px' }} variant="outlined" component={NavLink} to="/register">
                                            {t('login.navToSignUp')}
                                        </Button>
                                    </Box>
                                    : <Alert severity="error">{loginError}</Alert>)}

                            </Grid>
                        )}

                        <Grid item xs={12} container spacing={2} justifyContent="center">
                            <Grid item>
                                <IconButton>
                                    <GoogleLogin
                                        size={'large'}
                                        type={'icon'}
                                        shape={'pill'}
                                        onSuccess={handleLoginGoogle}
                                        onError={() => {
                                            console.log('Login Failed');
                                        }}
                                    />
                                </IconButton>
                            </Grid>

                            <Grid item>

                                <FacebookLogin
                                    appId="867462195031455"
                                    onSuccess={(response) => {
                                        setFBAccessToken(response.accessToken)
                                        // response. holds a HMAC SHA-256-signed object which can be validated by backend.
                                    }}
                                    onFail={(error) => {
                                        console.log('Login Failed!', error);
                                    }}
                                    onProfileSuccess={(response) => {
                                        setFBemail(response.email || "")
                                        setFBPictureURL(response.picture?.data.url || "")
                                        setFBuserId(response.id || "")
                                    }}
                                    render={({ onClick, logout }) => (
                                        <IconButton
                                            size="large" edge="end" color="inherit"
                                            onClick={onClick}
                                        >
                                            <BsFacebook color={"#1877F2"} />
                                        </IconButton>
                                    )}
                                />
                            </Grid>

                            <Grid item>
                                <IconButton
                                    size="large" edge="end" color="inherit"
                                    onClick={() => console.log("Insta")}
                                >
                                    <AiOutlineInstagram />
                                </IconButton>
                            </Grid>

                            <Grid item>
                                <IconButton
                                    size="large" edge="end" color="inherit"
                                    onClick={() => console.log("Apple")}
                                >
                                    <AiOutlineApple />
                                </IconButton>
                            </Grid>

                            <Grid item>
                                <IconButton
                                    size="large" edge="end" color="inherit"
                                    onClick={() => console.log("Github")}
                                >
                                    <AiOutlineGithub />
                                </IconButton>
                            </Grid>

                        </Grid>

                        <Grid item xs={12} container justifyContent="center">
                            <Box sx={{ width: "50%", height: "20px", borderBottom: "1px dashed gray", textAlign: "center", marginBottom: "30px" }}>
                                <Typography component="span" sx={{ fontSize: "15px", backgroundColor: "white", padding: "0 10px", color: "gray" }}>
                                    {t('login.or')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} container justifyContent="center">
                            <Stack spacing={2} sx={{ width: "50%" }}>
                                <TextField
                                    label={t('login.usernameOrEmail')}
                                    variant="outlined"
                                    fullWidth
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />

                                <PasswordInput
                                    sx={{ width: "100%" }}
                                    password={password}
                                    onPasswordChange={setPassword}
                                    title={t('login.password')}
                                    onSubmit={handleLogin}
                                />
                            </Stack>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            columnSpacing={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography variant="body2">
                                    {t('login.forgotPassword')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button component={NavLink} to="/reset-password">
                                    {t('login.resetPassword')}
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            spacing={2}
                            container
                            justifyContent="center"
                        >
                            <Grid item>
                                <Button sx={{ width: '200px', fontSize: '16px' }} variant="outlined" component={NavLink} to="/register">
                                    {t('login.navToSignUp')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    loading={loading}
                                    variant="contained"
                                    color="primary"
                                    sx={{ width: "200px", fontSize: "16px" }}
                                    onClick={handleLogin}
                                >
                                    {t('login.login')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            </Container>
        </>
    );
};

export default withAiAnimation(Login);
