import { AppendNodeWidget } from './AppendNodeWidget';
import { AppendNodeModel } from './AppendNodeModel';
import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import {GenerateModelEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory";
import {GenerateWidgetEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractReactFactory";

export class AppendNodeFactory extends AbstractReactFactory<AppendNodeModel, DiagramEngine> {
    constructor() {
        super('Node_APPEND');
    }

    generateReactWidget(event: GenerateWidgetEvent<any>): JSX.Element {
        return <AppendNodeWidget engine={this.engine} width={303} height={179} node={event.model} />;
    }

    generateModel(event: GenerateModelEvent) {
        return new AppendNodeModel();
    }
}