import ApiClient, { ClientOptions } from "./ApiClient";
import { createContext, useContext } from "react";
import axios, { AxiosProgressEvent } from "axios";
import { useNotificationContext } from "../contexts/NotificationContext";

export default class S3Client extends ApiClient {

    constructor() {
        super()
        this.BASE_URL = "";
    }

    public uploadFile = async (url: string, file: File, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) => {
        return axios.put<File>(url, file, {
            headers: {},
            onUploadProgress
        },);
    }

}


export const S3ClientContext = createContext<S3Client>(new S3Client())

export const useS3Client = (defaultConfig?: ClientOptions) => {
    const context = useContext(S3ClientContext)
    context.notificationCtx = useNotificationContext()
    if (defaultConfig) {
        context.defaultConfig = defaultConfig
    }
    return context
}