import React, { useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, Button,
    ListItemText, List, IconButton, FormControl, TextField, Grid,
} from '@mui/material';
import DialogHeader from '../../../../../shared/components/dialogs/DialogHeader';
import {Box, styled} from '@mui/system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {BiArrowToRight} from "react-icons/bi";
import {RemoveColNodeModel} from "../RemoveColNodeModel";

interface RemoveColsNodeSettingsProps {
    open: boolean
    handleClose: () => void
    nodeModel: RemoveColNodeModel
    comment: string
    setComment: React.Dispatch<React.SetStateAction<string>>
    setSettingsSummary: React.Dispatch<React.SetStateAction<string>>
}

const StyledBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px'
});

const LeftBox = styled(Box)({
    width: '100px',
    height: '40px',
    backgroundColor: 'lightblue',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px'
});

const RightBox = styled(Box)(({ gray }: { gray: boolean }) => ({
    width: '100px',
    height: '40px',
    backgroundColor: gray ? 'gray' : 'lightgreen',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '8px'
}));


const RemoveColsNodeSettings: React.FC<RemoveColsNodeSettingsProps> = (props: RemoveColsNodeSettingsProps) => {

    const dialogContentRef = React.useRef<HTMLDivElement>(null);

    const [inputCols, setInputCols] = useState<string[]>([]);
    const [grayBoxes, setGrayBoxes] = useState<boolean[]>([]);

    const handleArrowClick = (index: number) => {
        const newGrayBoxes = [...grayBoxes];
        newGrayBoxes[index] = !newGrayBoxes[index];
        setGrayBoxes(newGrayBoxes);
    };

    const onApply = () => {
        const outColNames = []
        const removedColNames = []
        for (let i = 0; i < inputCols.length; i++) {
            if (!grayBoxes[i]) {
                outColNames.push(inputCols[i])
            } else {
                removedColNames.push(inputCols[i])
            }
        }
        props.nodeModel.setOutputColumns(outColNames)

        if (removedColNames.length <= 2) {
            props.setSettingsSummary(removedColNames.join(", "))
        }
        props.nodeModel.setComment(props.comment)
        props.nodeModel.setRemovedColumns(removedColNames)

        onClose()
    }

    const onClose = () => {
        props.handleClose && props.handleClose()
    }

    // dont propagate events to componente below
    React.useEffect(() => {
        if (!props.open) return
        const dialogContent = dialogContentRef.current;
        const preventScroll = (e: WheelEvent) => {
            // e.preventDefault();
            e.stopPropagation();
        };

        const attachEventListener = () => {
            if (dialogContent) {
                dialogContent.addEventListener('wheel', preventScroll);
            }
        };

        if (props.open) {
            // Use setTimeout to ensure the dialog content is rendered before attaching the event listener
            setTimeout(attachEventListener, 0);
        }

        return () => {
            if (dialogContent) {
                dialogContent.removeEventListener('wheel', preventScroll);
            }
        };
    }, [props.open, dialogContentRef]);

    // used to re-load the filter-settings after the stream was reconstructed from file
    React.useEffect(()=>{
        setInputCols(props.nodeModel.inputColumns)
        const newGrayBoxes = props.nodeModel.inputColumns.map(() => false);
        props.nodeModel.inputColumns.forEach((colName: string, idx: number) => {
            if (props.nodeModel.removedColumns.includes(colName)) {
                newGrayBoxes[idx] = true;
            }
        })
        setGrayBoxes(newGrayBoxes);

        // eslint-disable-next-line
    }, [props.nodeModel.inputColumns, props.nodeModel.removedColumns])

    React.useEffect(()=>{
        props.setComment(props.nodeModel.comment)
        // eslint-disable-next-line
    }, [props.nodeModel.comment])

    return (
        <>
            <Dialog open={props.open} onClose={onClose}
                    maxWidth="lg"
                    scroll="paper"
                    PaperProps={{ sx: { backgroundColor: "white" } }}
                    disableEnforceFocus
                    fullWidth>
                <DialogHeader
                    headline={"Filter Columns "}
                    closeDialog={onClose}
                />
                <DialogContent ref={dialogContentRef}>

                    <List>
                        {inputCols.map((item, index) => (
                            <StyledBox key={index}>
                                <LeftBox>
                                    <ListItemText primary={item} />
                                </LeftBox>
                                <IconButton onClick={() => handleArrowClick(index)}>
                                    {grayBoxes[index] ?
                                        <BiArrowToRight /> :
                                        <ArrowForwardIcon style={{ textDecoration: grayBoxes[index] ? 'line-through' : 'none' }} />
                                    }
                                </IconButton>
                                <RightBox gray={grayBoxes[index]}>
                                    <ListItemText primary={item} />
                                </RightBox>
                            </StyledBox>
                        ))}
                    </List>

                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                label="Node Comment"
                                value={props.comment}
                                onChange={(e) => props.setComment(e.target.value)}
                                multiline
                                rows={2}
                            />
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onApply}>Apply</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RemoveColsNodeSettings;