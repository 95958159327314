import { GroupByNodeWidget } from './GroupByNodeWidget';
import { GroupByNodeModel } from './GroupByNodeModel';
import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import {GenerateModelEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory";
import {GenerateWidgetEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractReactFactory";

export class GroupByNodeFactory extends AbstractReactFactory<GroupByNodeModel, DiagramEngine> {
    constructor() {
        super('Node_GROUP_BY');
    }

    generateReactWidget(event: GenerateWidgetEvent<any>): JSX.Element {
        return <GroupByNodeWidget engine={this.engine} width={303} height={181} node={event.model} />;
    }

    generateModel(event: GenerateModelEvent) {
        return new GroupByNodeModel();
    }
}