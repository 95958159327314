import { SortNodeWidget } from './SortNodeWidget';
import { SortNodeModel } from './SortNodeModel';
import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import {GenerateModelEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory";
import {GenerateWidgetEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractReactFactory";

export class SortNodeFactory extends AbstractReactFactory<SortNodeModel, DiagramEngine> {
    constructor() {
        super('Node_SORT');
    }

    generateReactWidget(event: GenerateWidgetEvent<any>): JSX.Element {
        return <SortNodeWidget engine={this.engine} width={303} height={179} node={event.model} />;
    }

    generateModel(event: GenerateModelEvent) {
        return new SortNodeModel();
    }
}