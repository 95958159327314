import {ReadNodeModel} from "../../../components/diagram/nodes/read/ReadNodeModel";
import {
    DiagramModel,
    DiagramModelGenerics,
} from "@projectstorm/react-diagrams";
import * as React from "react";
import {useStreamContext} from "../../../contexts/StreamContext";
import {Paper} from "@mui/material";
import {ReactNode} from "react";
import {AddColNodeModel} from "../../../components/diagram/nodes/addCol/AddColNodeModel";
import {GroupByNodeModel} from "../../../components/diagram/nodes/groupBy/GroupByNodeModel";
import {FilterNodeModel} from "../../../components/diagram/nodes/filter/FilterNodeModel";
import {RemoveColNodeModel} from "../../../components/diagram/nodes/removeCols/RemoveColNodeModel";
import {MergeNodeModel} from "../../../components/diagram/nodes/merge/MergeNodeModel";
import {AppendNodeModel} from "../../../components/diagram/nodes/append/AppendNodeModel";
import {ReplaceValsNodeModel} from "../../../components/diagram/nodes/replaceValues/ReplaceValsNodeModel";
import {ReorderColsNodeModel} from "../../../components/diagram/nodes/reorderCols/ReorderColsNodeModel";
import {RenameColsNodeModel} from "../../../components/diagram/nodes/renameCols/RenameColsNodeModel";
import {SortNodeModel} from "../../../components/diagram/nodes/sort/SortNodeModel";
import {WriteNodeModel} from "../../../components/diagram/nodes/write/WriteNodeModel";
import {CanvasEngine} from "@projectstorm/react-canvas-core/dist/@types/CanvasEngine";


export interface StreamCanvasProps {
    handleNewNodeDragStart: (event: React.DragEvent<HTMLDivElement>, nodeType: string) => void;
    children: ReactNode;
    engine: CanvasEngine;
    model: DiagramModel<DiagramModelGenerics>;
}

const StreamCanvas: React.FC<StreamCanvasProps> = (props: StreamCanvasProps) => {

    const {selectNodeIds} = useStreamContext();

    // <editor-fold desc=" Drag&Dorp handlers ">


    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const nodeType = event.dataTransfer.getData('nodeType');

        if (canvasRef.current) {
            const canvasRect = canvasRef.current.getBoundingClientRect();
            const zoomFactor = props.engine.getModel().getZoomLevel() / 100;
            const offsetX = props.engine.getModel().getOffsetX();
            const offsetY = props.engine.getModel().getOffsetY();
            const x = (event.clientX - canvasRect.left - offsetX) / zoomFactor;
            const y = (event.clientY - canvasRect.top - offsetY) / zoomFactor;

            let newNode
            if (nodeType === "Read") {
                newNode = new ReadNodeModel("", "");
            } else if (nodeType === "Fill new Column") {
                newNode = new AddColNodeModel();
            } else if (nodeType === "Group by Column(s)") {
                newNode = new GroupByNodeModel();
            } else if (nodeType === "Filter Rows") {
                newNode = new FilterNodeModel();
            } else if (nodeType === "Remove Columns") {
                newNode = new RemoveColNodeModel();
            } else if (nodeType === "Merge") {
                newNode = new MergeNodeModel();
            } else if (nodeType === "Append") {
                newNode = new AppendNodeModel();
            } else if (nodeType === "Replace Values") {
                newNode = new ReplaceValsNodeModel();
            } else if (nodeType === "Re-Order Columns") {
                newNode = new ReorderColsNodeModel();
            } else if (nodeType === "Rename Cols") {
                newNode = new RenameColsNodeModel();
            } else if (nodeType === "Sort Cols") {
                newNode = new SortNodeModel();
            } else if (nodeType === "Write") {
                newNode = new WriteNodeModel();
            }

            if (newNode) {
                newNode.setPosition(x, y);
                props.model.addNode(newNode);
                /*
                const newModel = props.model.addNode(newNode);
                newModel.registerListener({
                    selectionChanged: () => { console.log("selectionChanged") },
                    nodesUpdated: () => { console.log("nodesUpdated") },
                    linksUpdated: () => { console.log("linksUpdated") },
                    offsetUpdated: () => { console.log("offsetUpdated") },
                    zoomUpdated: () => { console.log("zoomUpdated") },
                    gridUpdated: () => { console.log("gridUpdated") },
                    entityRemoved: () => { console.log("entityRemoved") },
                });*/

                newNode.registerListener({
                    /* eventDidFire: (event: any) => {
                        console.log('element eventDidFire')
                        console.log(event)
                    }, */
                    selectionChanged: (event:any) => {
                        console.log('element selectionChanged')
                        console.log(event)
                    }
                    // positionChanged
                    // nodesUpdated
                });

                props.engine.repaintCanvas();
            }
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };
    // </editor-fold>

    // ensure Canvas access its props only once it is loaded fully
    const canvasRef = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => {
        if (canvasRef.current) {
            props.engine.getModel().registerListener({
                nodesUpdated: () => {
                    console.log('Nodes updated');
                },
            });
        }
    }, [canvasRef, props.engine]);



    const handleOnCanvasClicked = (event: React.MouseEvent<HTMLDivElement>): void => {
        event.stopPropagation()
        console.log("handleOnCanvasClicked")
        // de-select any selected node
        selectNodeIds([])
    }



    return (
        <Paper ref={canvasRef} sx={{
            width: "100%",
            height: "80vh",
            backgroundColor: "rgba(255,255,255, 0.5)",
            '.canvas': {
                width: "100%",
                height: "100%",
            }
        }}
               onDrop={handleDrop}
               onDragOver={handleDragOver}
               onClick={handleOnCanvasClicked}
        >
            {props.children}
        </Paper>
    );
};

export default StreamCanvas;

