import { RenameColsNodeWidget } from './RenameColsNodeWidget';
import { RenameColsNodeModel } from './RenameColsNodeModel';
import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import {GenerateModelEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory";
import {GenerateWidgetEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractReactFactory";

export class RenameColsNodeFactory extends AbstractReactFactory<RenameColsNodeModel, DiagramEngine> {
    constructor() {
        super('Node_RENAME_COLS');
    }

    generateReactWidget(event: GenerateWidgetEvent<any>): JSX.Element {
        return <RenameColsNodeWidget engine={this.engine} width={303} height={179} node={event.model} />;
    }

    generateModel(event: GenerateModelEvent) {
        return new RenameColsNodeModel();
    }
}