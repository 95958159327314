import Cookies from "universal-cookie";
import { UserContext } from "../contexts/UserContext";
import { NotificationContext } from "../contexts/NotificationContext";
import { ErrorResponse } from "./error/ErrorResponse";

export enum IdProvider {
    SPARKYETL = "SPARKYETL",
    GOOGLE = "GOOGLE",
    FACEBOOK = "FACEBOOK",
}

export interface ClientOptions {
    preventTokenRefresh?: boolean
    preventBodyStringify?: boolean
    preventAuthorization?: boolean
    preventNotification?: boolean
    catchExceptions?: boolean
}

export interface RequestOptions extends ClientOptions {
    body?: unknown
}

declare global {
    interface Window { REACT_APP_BASE_URL: string }
}

export default class ApiClient {
    protected BASE_URL: string;
    protected AUTHENTICATION_HEADER: boolean;

    private _defaultConfig: ClientOptions;

    private _userCtx: UserContext | undefined;
    private _notificationCtx: NotificationContext | undefined;

    constructor() {
        this.BASE_URL = window.REACT_APP_BASE_URL;
        this.AUTHENTICATION_HEADER = true;
        this._defaultConfig = {}
    }

    private optionsWithDefault = (options: RequestOptions): RequestOptions => {
        return {
            body: options.body,
            preventAuthorization: options.preventAuthorization === undefined ? this.defaultConfig.preventAuthorization : options.preventAuthorization,
            preventNotification: options.preventNotification === undefined ? this.defaultConfig.preventNotification : options.preventNotification,
            preventBodyStringify: options.preventBodyStringify === undefined ? this.defaultConfig.preventBodyStringify : options.preventBodyStringify,
            preventTokenRefresh: options.preventTokenRefresh === undefined ? this.defaultConfig.preventTokenRefresh : options.preventTokenRefresh,
            catchExceptions: options.catchExceptions === undefined ? this.defaultConfig.catchExceptions : options.catchExceptions,
        }
    }

    protected request = async (method: string, url: string, options: RequestOptions) => {
        options = this.optionsWithDefault(options)
        const cookies = new Cookies()

        const headers: HeadersInit = new Headers()
        !options.preventAuthorization && headers.append('Authorization', ('Bearer ' + (cookies.get("token") ?? '')));
        headers.set('Content-Type', 'application/json')
        headers.set('X-ID-PROVIDER', cookies.get("x-id-provider"))

        const parseBody = (): string | undefined => {
            if (options.body) {
                if (options.preventBodyStringify) {
                    return options.body as string
                } else {
                    return JSON.stringify(options.body)
                }
            }
            return undefined
        }

        return fetch(this.BASE_URL + url, {
            method: method,
            headers: headers,
            body: parseBody()
        }).then((response: Response) => {
            if (response.status < 200 || response.status >= 300) {
                throw response;
            }
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json();
            } else {
                return response.text();
            }
        })
            .catch(async (error: Response | unknown) => {
                if (error instanceof Response) {
                    if (error.status === 401 && this.userCtx && !options.preventTokenRefresh) {
                        this.userCtx.refreshToken()
                    }
                    const body = await error.json() as ErrorResponse
                    body.status = error.status
                    if (this.notificationCtx && body.message && !options.preventNotification) {
                        this.notificationCtx.notify({ message: body.message, severity: 'error' })
                    }
                    throw body
                }
                throw error
            })
            .catch(e => {
                if (!options.catchExceptions) {
                    throw e
                }
            });
    }
    protected get = async (url: string, options?: RequestOptions) => {
        return this.request('GET', url, options ?? {});
    }

    protected post = async (url: string, options?: RequestOptions) => {
        return this.request('POST', url, options ?? {});
    }

    protected put = async (url: string, options?: RequestOptions) => {
        return this.request('PUT', url, options ?? {});
    }

    protected patch = async (url: string, options?: RequestOptions) => {
        return this.request('PATCH', url, options ?? {});
    }

    protected delete = async (url: string, options?: RequestOptions) => {
        return this.request('DELETE', url, options ?? {});
    }



    public get notificationCtx(): NotificationContext | undefined {
        return this._notificationCtx;
    }
    public set notificationCtx(value: NotificationContext | undefined) {
        this._notificationCtx = value;
    }
    public get userCtx(): UserContext | undefined {
        return this._userCtx;
    }
    public set userCtx(value: UserContext | undefined) {
        this._userCtx = value;
    }
    public get defaultConfig(): ClientOptions {
        return this._defaultConfig;
    }
    public set defaultConfig(value: ClientOptions) {
        this._defaultConfig = value;
    }
}