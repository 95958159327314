import {Fab, Grid, Tooltip} from '@mui/material';
import * as React from 'react';
import ClusterView from '../cluster/ClusterView';
import {ClusterContextProvider} from '../../contexts/ClusterContext';
import {withAiAnimation} from '../../shared/AiAnimation';
import StartStream from './components/StartStream';
import InputIcon from '@mui/icons-material/Input';
import {CiFilter} from "react-icons/ci";
import {MdFindReplace, MdJoinInner} from "react-icons/md";
import {CgArrowsMergeAltV, CgRename} from "react-icons/cg";
import OutputIcon from '@mui/icons-material/Output';

import {HiOutlineUserGroup} from "react-icons/hi";
import {RiDeleteColumn, RiInsertColumnLeft} from "react-icons/ri";
import {TbReorder} from "react-icons/tb";
import {BsSortDown} from "react-icons/bs";

import createEngine, {
    CanvasWidget, DefaultDiagramState, DiagramEngine,
    DiagramModel, NodeModel, PortModelAlignment
} from '@projectstorm/react-diagrams';
import {ReadNodeFactory} from "../../components/diagram/nodes/read/ReadNodeFactory";
import {GroupByNodeFactory} from "../../components/diagram/nodes/groupBy/GroupByNodeFactory";
import {AddColNodeFactory} from "../../components/diagram/nodes/addCol/AddColNodeFactory";
import {RemoveColNodeFactory} from "../../components/diagram/nodes/removeCols/RemoveColNodeFactory";
import {FilterNodeFactory} from "../../components/diagram/nodes/filter/FilterNodeFactory";
import {AppendNodeFactory} from "../../components/diagram/nodes/append/AppendNodeFactory";
import {MergeNodeFactory} from "../../components/diagram/nodes/merge/MergeNodeFactory";
import {SortNodeFactory} from "../../components/diagram/nodes/sort/SortNodeFactory";
import {RenameColsNodeFactory} from "../../components/diagram/nodes/renameCols/RenameColsNodeFactory";
import {ReorderColsNodeFactory} from "../../components/diagram/nodes/reorderCols/ReorderColsNodeFactory";
import {ReplaceValsNodeFactory} from "../../components/diagram/nodes/replaceValues/ReplaceValsNodeFactory";
import {WriteNodeFactory} from "../../components/diagram/nodes/write/WriteNodeFactory";
import NodeToolboxSpeedDial from "../../shared/components/streamComponents/NodeToolboxSpeedDial";
import {StreamContextProvider} from "../../contexts/StreamContext";
import StreamCanvas from "../../shared/components/streamComponents/StreamCanvas";
import { LuSave, LuDownloadCloud, LuUploadCloud } from "react-icons/lu";
import JSZip from 'jszip';
import {SimplePortFactory} from "../../components/diagram/port/SimplePortFactory";
import {SparkyELTPortModel} from "../../components/diagram/port/SparkyELTPortModel";
import {useStreamApiClient} from "../../clients/StreamApiClient";
import FileManagementApiClient, {useFileManagementApiClient} from "../../clients/FileManagementApiClient";
import {ReadNodeModel} from "../../components/diagram/nodes/read/ReadNodeModel";

// read/write
// - read
// - write

// Create & Aggregate
// - Fill new Col
// - Group by Cols

// Remove
// - Filter Lines
// - Filter Rows

// Combine
// - Merge
// - Append

// Modify
// - Replace Values
// - Re-Order COlumns
// - Rename Cols
// - Sort Cols



const actionsRead = [
    { icon: <InputIcon />, name: 'Read' },
];

const actionsCreateAgg = [
    { icon: <RiInsertColumnLeft />, name: 'Fill new Column' },
    { icon: <HiOutlineUserGroup />, name: 'Group by Column(s)' },
];

const actionsRemove = [
    { icon: <CiFilter />, name: 'Filter Rows' },
    { icon: <RiDeleteColumn />, name: 'Remove Columns' },
];

const actionsCombine = [
    { icon: <MdJoinInner />, name: 'Merge' },
    { icon: <CgArrowsMergeAltV />, name: 'Append' },
];

const actionModify = [
    { icon: <MdFindReplace />, name: 'Replace Values' },
    { icon: <TbReorder />, name: 'Re-Order Columns' },
    { icon: <CgRename />, name: 'Rename Cols' },
    { icon: <BsSortDown />, name: 'Sort Cols' },
];

const actionsWrite = [
    { icon: <OutputIcon />, name: 'Write' },
];


interface IDemoProps {
}

/*
let model = engine.getModel();
const nodes = model.getNodes();
let node = nodes[Object.keys(nodes)[0]];
node.setPosition(node.getX() + 30, node.getY() + 30);
engine.repaintCanvas();

// serialize
let model = engine.getModel();
var str = JSON.stringify(model.serialize());

// deserialize
var model2 = new DiagramModel();
model2.deserializeModel(JSON.parse(str), engine);
engine.setModel(model2);

 */

// const downloadStringAsFile = (content: string, filename: string, contentType: string = 'text/plain'): void => {
const downloadStringAsFile = async (content: string, filename: string, zipFilename: string, contentType: string = 'application/json'): Promise<void> => {
    // Create a new instance of JSZip
    const zip = new JSZip();

    // Add the JSON file to the ZIP
    zip.file(filename, content);

    // Generate the ZIP file asynchronously
    const blob = await zip.generateAsync({ type: 'blob' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element and set its href attribute to the Blob URL
    const a = document.createElement('a');
    a.href = url;
    a.download = zipFilename;

    // Append the anchor to the document body and trigger a click event on it
    document.body.appendChild(a);
    a.click();

    // Remove the anchor from the document body and revoke the Blob URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

const getChildNodeModels = (node: any): { childNodeModels: any[], childNodesInputPortNames: string[] } => {

    const childNodeModels: NodeModel[] = [];
    const childNodesInputPortNames: string[] = [];
    // Iterate over all ports of the node
    Object.values(node.getPorts()).forEach((port: any) => {
        // Iterate over all links connected to this port
        Object.values(port.getLinks()).forEach((link: any) => {
            // Check if this port is the source of the link
            const targetPort = link.getTargetPort();
            const tgtPortName = targetPort.getName() // input, inputTop, inputBottom
            if (targetPort && link.getSourcePort() === port) {
                const targetNode = targetPort.getNode();
                if (targetNode) {
                    childNodeModels.push(targetNode);
                    childNodesInputPortNames.push(tgtPortName)
                }
            }
        });
    });

    return {childNodeModels, childNodesInputPortNames};
}

const propagateColsToChildren = (model: any) => {
    const outputCols: string[] = model.calculateOutputCols();
    const { childNodeModels, childNodesInputPortNames } = getChildNodeModels(model)
    if (childNodeModels.length === 0) {
        return
    } else {
        childNodeModels.forEach((model: any, idx) => {
            if (childNodesInputPortNames[idx] === "input") {
                model.setInputColumns(outputCols)
            } else if (childNodesInputPortNames[idx] === "inputTop") {
                model.setInputColumnsTop(outputCols)
            } else if (childNodesInputPortNames[idx] === "inputBottom") {
                model.setInputColumnsBottom(outputCols)
            }

            propagateColsToChildren(model)
        })
    }

}

const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, engine: DiagramEngine,
                                setModel: React.Dispatch<React.SetStateAction<DiagramModel>>,
                                fileClient: FileManagementApiClient
) => {

    const file = event.target.files?.[0];
    if (!file) {
        return;
    }

    if (file.name.toLowerCase().endsWith('.zip') || file.name.toLowerCase().endsWith('.sparkyetl')) {
        try {
            const zip = new JSZip();
            const content = await zip.loadAsync(file);
            const fileNames = Object.keys(content.files);

            // Assume there is only one JSON file in the zip
            const jsonFileName = fileNames.find(name => name.endsWith('.json'));
            if (jsonFileName) {
                const jsonFile = content.files[jsonFileName];
                const jsonString = await jsonFile.async('string');
                const model2 = new DiagramModel();
                model2.deserializeModel(JSON.parse(jsonString), engine);
                engine.setModel(model2)
                setModel(model2)

                // w8 some time so all attributes (connections) get propagated...
                await new Promise(resolve => setTimeout(resolve, 1000));

                // find start-nodes
                const startNodes: any[] = [];
                model2.getNodes().forEach((node: NodeModel) => {
                    let hasIncomingConnection = false;
                    // Iterate through each port of the node
                    Object.values(node.getPorts()).forEach((port) => {
                        // Check all links connected to this port
                        Object.values(port.getLinks()).forEach((link: any) => {
                            // If the port is the target of the link, it's an incoming connection
                            if (link.getTargetPort() === port) {
                                hasIncomingConnection = true;
                            }
                        });
                    });

                    // If no incoming connection was found, this is a start-node
                    if (!hasIncomingConnection) {
                        startNodes.push(node);
                    }
                });

                // propagate cols to each node
                startNodes.forEach((nodeModel: any) => {
                    if (nodeModel instanceof ReadNodeModel) {
                        // const connectionId = nodeModel.getSelectedConnectionId()
                        const selFile = nodeModel.getSelectedSourceElement()
                        const tmp = selFile.split("/")
                        const path = tmp.slice(0, -1).join('/');
                        const filenam = tmp[tmp.length - 1];
                        fileClient.getFilePreview(path, filenam)
                            .then((result: any)=> {
                                let colTypes: Record<string, string> = {}
                                for (const colName in result.fields) {
                                    colTypes[colName] = "string"
                                }
                                nodeModel.setOutputColumns(result.fields)
                                propagateColsToChildren(nodeModel)
                            })
                    }

                })
            } else {
                console.error('No JSON file found in the ZIP archive.');
            }
        } catch (error) {
            console.error('Error reading ZIP file:', error);
        }
    } else if (file.name.toLowerCase().endsWith('.json') ) {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target) {
                const jsonString = e.target.result
                if (jsonString && typeof jsonString === "string") {
                    const model2 = new DiagramModel()
                    model2.deserializeModel(JSON.parse(jsonString), engine)
                    setModel(model2)
                    engine.setModel(model2)
                }
            }
        }
        reader.readAsText(file);
    } else {
        console.error('Unsupported file type.');
    }
};

const Demo: React.FunctionComponent<IDemoProps> = (props: IDemoProps) => {

    const streamClient = useStreamApiClient()
    const fileClient = useFileManagementApiClient()


    const [engine] = React.useState<DiagramEngine>(() => createEngine());
    const [model, setModel] = React.useState<DiagramModel>(() => new DiagramModel())


    function propagateColnames(sourceModel: any, sourcePortName: string, targetModel: any, targetPortName: string) {
        let outCols = []
        if (sourcePortName === 'output') {
            outCols = sourceModel.getOutputColumns()
        } else if (sourcePortName === 'outputTop') {
            outCols = sourceModel.getOutputColumnsTop()
        } else if (sourcePortName === 'outputBottom') {
            outCols = sourceModel.getOutputColumnsBottom()
        }

        if (targetPortName === 'input') {
            targetModel.setInputColumns(outCols)
        } else if (targetPortName === 'inputTop') {
            targetModel.setInputColumnsTop(outCols)
        } else if (targetPortName === 'inputBottom') {
            targetModel.setInputColumnsBottom(outCols)
        }
    }

    React.useEffect(() => {
        // Don't allow "loose" links (connections not ending on a port)
        const state = engine.getStateMachine().getCurrentState();
        if (state instanceof DefaultDiagramState) {
            state.dragNewLink.config.allowLooseLinks = false;
        }

        // register some other factories as well

        // Register custom port factory
        engine.getPortFactories().registerFactory(new SimplePortFactory('diamond', (config) => new SparkyELTPortModel(PortModelAlignment.LEFT, 'input')));

        // engine
        //     .getPortFactories()
        //     .registerFactory(new SimplePortFactory('diamond', (config) => new SparkyELTPortModel(PortModelAlignment.LEFT)));
        engine.getNodeFactories().registerFactory(new ReadNodeFactory());
        engine.getNodeFactories().registerFactory(new GroupByNodeFactory());
        engine.getNodeFactories().registerFactory(new AddColNodeFactory());
        engine.getNodeFactories().registerFactory(new RemoveColNodeFactory());
        engine.getNodeFactories().registerFactory(new FilterNodeFactory());
        engine.getNodeFactories().registerFactory(new AppendNodeFactory());
        engine.getNodeFactories().registerFactory(new MergeNodeFactory());
        engine.getNodeFactories().registerFactory(new SortNodeFactory());
        engine.getNodeFactories().registerFactory(new RenameColsNodeFactory());
        engine.getNodeFactories().registerFactory(new ReorderColsNodeFactory());
        engine.getNodeFactories().registerFactory(new ReplaceValsNodeFactory());
        engine.getNodeFactories().registerFactory(new WriteNodeFactory());

        const model = new DiagramModel();

        model.setGridSize(25)
        model.setZoomLevel(50)
        model.registerListener({
            eventDidFire: (event: any) => {
                if (event.link) {
                    event.link.registerListener({
                        targetPortChanged: (event2: any) => {

                            // this event will fire once a connection between ports was establshed.
                            // now fetch the out-columns from source node
                            // and pass it on to targetNode
                            const sourceModel = event2.entity.sourcePort.parent
                            const targetModel = event2.entity.targetPort.parent

                            const sourcePortName = event2.entity.sourcePort.getName()
                            const targetPortName = event2.entity.targetPort.getName()

                            propagateColnames(sourceModel, sourcePortName, targetModel, targetPortName)
                        }
                    })
                    /*linksUpdated: (event) => {
                        event.link.registerListener({
                            targetPortChanged: (event) => {
                                console.log('Link Changed');
                                debugger
                            }
                        })
                    }*/
                    console.log(event)
                }

            },

        })

        /*
        // node 1
        const node1 = new DefaultNodeModel({
            name: 'Read CSV-File',
            color: '#93BF79',
        });
        node1.setPosition(100, 100);
        let port1 = node1.addOutPort('Out');

        // node 2
        const node2 = new DefaultNodeModel({
            name: 'Node 2',
            color: 'rgb(0,192,255)',
        });
        node2.setPosition(300, 100);
        let port2 = node2.addInPort('In');


        // link them and add a label to the link
        const link = port1.link<DefaultLinkModel>(port2);
        link.addLabel('Hello World!');
        link.setWidth(5);
        */

        /*
        var node5 = new ReadNodeModel("", "");
        node5.setPosition(100, 100);

        var node6 = new GroupByNodeModel();
        node6.setPosition(400, 100);

        var node7 = new AddColNodeModel();
        node7.setPosition(800, 100);

        var node8 = new RemoveColNodeModel();
        node8.setPosition(1200, 100);

        var node9 = new FilterNodeModel();
        node9.setPosition(1600, 100);

        var node10 = new AppendNodeModel();
        node10.setPosition(2000, 100);

        var node11 = new MergeNodeModel();
        node11.setPosition(2400, 100);

        var node12 = new SortNodeModel();
        node12.setPosition(2800, 100);

        var node13 = new RenameColsNodeModel();
        node13.setPosition(3200, 100);

        var node14 = new ReorderColsNodeModel();
        node14.setPosition(3600, 100);

        var node15 = new ReplaceValsNodeModel();
        node15.setPosition(4000, 100);

        var node16 = new WriteNodeModel();
        node16.setPosition(4400, 100);

        model.addAll(node5, node6, node7, node8, node9, node10, node11, node12, node13, node14, node15, node16);
        */
        /*
        model.registerListener({
            eventDidFire: (event: any) => {
                console.log('model eventDidFire')
                console.log(event)

            }
        }); */
        setModel(model);
        engine.setModel(model)
    }, [engine])

    // uncomment for perfomance demonstration
    // for (let i = 0; i < 1000; i++) {
    //     const node = new DefaultNodeModel({
    //         name: 'Node ' + i,
    //         color: 'rgb(255,192,255)',
    //     });
    //     node.setPosition(Math.random() * 1000, Math.random() * 500);
    //     node.addInPort('In');
    //     node.addOutPort('Out');

    //     model.addAll(node)
    // }

    const handleNewNodeDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string) => {
        event.dataTransfer.setData('nodeType', nodeType);
    };

    const onDownloadClicked = (): void => {
        const content = JSON.stringify(model.serialize());
        downloadStringAsFile(content, "stream.json", "stream.sparkyETL")
    }

    const onSaveStreamClicken = (): void => {
        const streamID = "aa027e1d-867c-4d2b-9237-d16b5fc96754"
        const streamName = "Demo-Stream"
        const content = JSON.stringify(model.serialize());
        streamClient.saveStream(streamID, streamName, content)
    }

    const onUploadClicked = (): void => {
        const elem = document.getElementById('file-input')
        if (elem) {
            elem.click();
        }
    }

    return (
        <>
            {model && engine.getModel() &&
                <ClusterContextProvider>
                    <ClusterView />
                    <StartStream />
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12}>
                            <StreamContextProvider>
                                <StreamCanvas engine={engine} model={model} handleNewNodeDragStart={handleNewNodeDragStart}>
                                    <CanvasWidget className='canvas' engine={engine} />
                                </StreamCanvas>
                            </StreamContextProvider>
                        </Grid>
                    </Grid >
                </ClusterContextProvider >
            }

            <Tooltip title={"Save Stream"}>
                <Fab disabled={false} size="small" onClick={onSaveStreamClicken} color="default"
                     sx={{ position: 'absolute', left: "150px", top: "50px", }}
                >
                    <LuSave style={{ fontSize: 25 }}/>
                </Fab>
            </Tooltip>

            <Tooltip title={"Download Stream"}>
                <Fab disabled={false} size="small" onClick={onDownloadClicked} color="default"
                     sx={{ position: 'absolute', left: "500px", top: "50px", }}
                >
                    <LuDownloadCloud style={{ fontSize: 25 }}/>
                </Fab>
            </Tooltip>

            <Tooltip title={"Upload Stream"}>
                <Fab disabled={false} size="small" onClick={onUploadClicked} color="default"
                     sx={{ position: 'absolute', left: "580px", top: "50px", }}
                >
                    <LuUploadCloud style={{ fontSize: 25 }}/>
                </Fab>
            </Tooltip>
            <input type="file" id="file-input" accept=".json,.sparkyetl,.zip" style={{display: "none"}} onChange={
                (event: React.ChangeEvent<HTMLInputElement>) => {
                    handleFileUpload(event, engine, setModel, fileClient)
                }}
            />


            <NodeToolboxSpeedDial
                toolboxNodes={actionsRead}
                handleNewNodeDragStart={handleNewNodeDragStart}
                bgcolor={'#93BF79'}
                posBottom={16} posRight={440}
            />

            <NodeToolboxSpeedDial
                toolboxNodes={actionsCreateAgg}
                handleNewNodeDragStart={handleNewNodeDragStart}
                bgcolor={'#D3CC84'}
                posBottom={16} posRight={355}
            />

            <NodeToolboxSpeedDial
                toolboxNodes={actionsRemove}
                handleNewNodeDragStart={handleNewNodeDragStart}
                bgcolor={'#DBB78B'}
                posBottom={16} posRight={270}
            />

            <NodeToolboxSpeedDial
                toolboxNodes={actionsCombine}
                handleNewNodeDragStart={handleNewNodeDragStart}
                bgcolor={'#E3A293'}
                posBottom={16} posRight={185}
            />

            <NodeToolboxSpeedDial
                toolboxNodes={actionModify}
                handleNewNodeDragStart={handleNewNodeDragStart}
                bgcolor={'#DBC8FC'}
                posBottom={16} posRight={100}
            />

            <NodeToolboxSpeedDial
                toolboxNodes={actionsWrite}
                handleNewNodeDragStart={handleNewNodeDragStart}
                bgcolor={'#E3EDFF'}
                posBottom={16} posRight={16}
            />
        </>
    );
}

export default withAiAnimation(Demo);