import * as React from "react";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ClusterRunningState, ClusterStatus, ClusterSummary } from "../clients/model/Cluster";
import { useClusterApiClient } from "../clients/ClusterApiClient";
import { useStreamApiClient } from "../clients/StreamApiClient";
import { useNotificationContext } from "./NotificationContext";



export interface ClusterContext {
    clusterId?: string;
    clusterStatus?: ClusterStatus;
    availableCluster?: Array<ClusterSummary>

    clusterLogs?: string
    sparkLogs?: string

    clusterLogsChanged?: boolean
    sparkLogsChanged?: boolean

    startCluster(): void;
    stopCluster(): void;
    setCluster(clusterId: string): void;

    clusterLogsViewed(): void;
    sparkLogsViewed(): void;

    startStream(): void;
}

const defaultClusterContext: ClusterContext = {
    startCluster: function (): void {
        throw new Error("Function not implemented.");
    },
    stopCluster: function (): boolean {
        throw new Error("Function not implemented.");
    },
    setCluster: function (clusterId: string): void {
        throw new Error("Function not implemented.");
    },
    clusterLogsViewed: function (): void {
        throw new Error("Function not implemented.");
    },
    sparkLogsViewed: function (): void {
        throw new Error("Function not implemented.");
    },
    startStream: function (): void {
        throw new Error("Function not implemented.");
    }
};

export const ClusterContextHolder = createContext<ClusterContext>(defaultClusterContext);

export const useClusterContext = () => {
    return useContext(ClusterContextHolder);
};

export interface IClusterContextProps { }

export const ClusterContextProvider: React.FunctionComponent<
    React.PropsWithChildren<IClusterContextProps>
> = (props: React.PropsWithChildren<IClusterContextProps>) => {


    const clusterClient = useClusterApiClient()
    const streamClient = useStreamApiClient()

    const notificationCtx = useNotificationContext()


    const statusTimerRef = useRef<NodeJS.Timer>();
    const logTimerRef = useRef<NodeJS.Timer>();

    const [clusterId, setClusterId] = useState<string>()
    const [clusterStatus, setClusterStatus] = useState<ClusterStatus>()
    const [availableCluster, setAvailableCluster] = useState<Array<ClusterSummary>>()

    const [clusterLogs, setClusterLogs] = useState<string>()
    const [sparkLogs, setSparkLogs] = useState<string>()

    const [clusterLogsChanged, setClusterLogsChanged] = useState(false)
    const [sparkLogsChanged, setSparkLogsChanged] = useState(false)

    const fetchAvailableClusters = useCallback(() => {
        clusterClient.getAllClusters()
            .then(clusters => setAvailableCluster(ac => [...clusters, ...ac ?? []].filter((value, index, self) => self.findIndex(s => s.id === value.id) === index)))
    }, [clusterClient])

    useEffect(() => {
        fetchAvailableClusters()
    }, [fetchAvailableClusters])

    useEffect(() => {
        if (!clusterId && (availableCluster?.length ?? 0) > 0) {
            setClusterId(availableCluster?.at(0)?.id)
        }
    }, [availableCluster, clusterId])


    const startCluster = () => {
        clusterClient.createCluster()
            .then(id => setClusterId(id))
            .then(() => fetchAvailableClusters())
            .catch(() => { })
    }
    const stopCluster = () => {
        clusterId && clusterClient.deleteCluster(clusterId)
            .then(() => fetchStatus())
            .catch(() => { })
    }


    const fetchStatus = useCallback(() => {
        clusterClient.getClusterStatus(clusterId ?? '')
            .then(status => setClusterStatus(status))
            .catch(() => { })
    }, [clusterClient, clusterId])

    const fetchLogs = useCallback(() => {
        if (clusterId) {
            clusterClient.getClusterLogs(clusterId)
                .then(logs => {
                    setClusterLogs(cl => {
                        if (cl !== logs && logs.length > 0) {
                            setClusterLogsChanged(true)
                        }
                        return logs
                    })
                })
                .catch(() => { })
            clusterClient.getSparkLogs(clusterId)
                .then(logs => {
                    setSparkLogs(sl => {
                        if (sl !== logs && logs.length > 0) {
                            setSparkLogsChanged(true)
                        }
                        return logs
                    })
                })
                .catch(() => { })

        }
    }, [clusterClient, clusterId])


    const startStatusPolling = useCallback(() => {
        fetchStatus()
        statusTimerRef.current = setInterval(fetchStatus, 10000);
    }, [fetchStatus])

    const startLogPolling = useCallback(() => {
        fetchLogs()
        logTimerRef.current = setInterval(fetchLogs, 10000);
    }, [fetchLogs])

    const stopStatusPolling = useCallback(() => {
        clearInterval(statusTimerRef.current);
        statusTimerRef.current = undefined
        fetchAvailableClusters()
    }, [fetchAvailableClusters])

    const stopLogPolling = useCallback(() => {
        clearInterval(logTimerRef.current);
        logTimerRef.current = undefined
    }, [])

    const setCluster = (clusterId: string) => {
        stopStatusPolling()
        stopLogPolling()
        setClusterId(clusterId)
    }

    useEffect(() => {
        if (clusterId) {
            startStatusPolling();
            startLogPolling();
        } else {
            stopStatusPolling();
            stopLogPolling();
        }
    }, [clusterId, startLogPolling, startStatusPolling, stopLogPolling, stopStatusPolling])

    useEffect(() => {
        if (clusterId) {
            setClusterLogsChanged(false)
            setSparkLogsChanged(false)
            setClusterLogs(undefined)
            setSparkLogs(undefined)
        }
    }, [clusterId])

    useEffect(() => {
        if (clusterStatus?.state === ClusterRunningState.TERMINATED) {
            stopStatusPolling()
        }
    }, [clusterStatus, stopStatusPolling])


    const clusterContext: ClusterContext = {
        clusterId: clusterId,
        clusterStatus: clusterStatus,
        availableCluster,
        clusterLogs,
        sparkLogs,
        clusterLogsChanged,
        sparkLogsChanged,
        startCluster,
        stopCluster,
        setCluster,
        clusterLogsViewed() {
            setClusterLogsChanged(false)
        },
        sparkLogsViewed() {
            setSparkLogsChanged(false)
        },
        startStream() {
            if (clusterId) {
                streamClient.startStream(clusterId)
                    .then(() => notificationCtx.notify({ message: 'Successfully triggered stream, please wait...', severity: 'success' }))
                    .catch(() => { })
            }
        }
    };

    return (
        <>
            <ClusterContextHolder.Provider value={clusterContext}>
                {props.children}
            </ClusterContextHolder.Provider>
        </>
    );
};
