import * as React from 'react';
import { useFileManagementApiClient } from '../../clients/FileManagementApiClient';
import { Folder } from '../../clients/model/Folder';
import Explorer, { FileItem, StreamItem, FolderItem, ExplorerItem } from './Explorer';
import InlineLoader from './InlineLoader';

export interface IFileExplorerProps {
    onFileClick?(item: FileItem): void
    onStreamClick?(item: StreamItem): void
    onFolderClick?(item: FolderItem): void
    onItemClick?(item: ExplorerItem): void
}

const FileExplorer: React.FunctionComponent<IFileExplorerProps> = (props: IFileExplorerProps) => {
    const fileClient = useFileManagementApiClient()

    const [folder, setFolder] = React.useState<Folder>()

    const fetchFolder = React.useCallback(() => {
        fileClient.getFolderStructure()
            .then(setFolder)
            .catch(() => { })
    }, [fileClient])

    React.useEffect(() => {
        fetchFolder()
    }, [fetchFolder])
    return (
        <>
            {folder ?
                <Explorer
                    enableFolderCreation
                    enableFileCreation
                    enableItemDeletion
                    data={folderToFileSystemItems(folder)}
                    onNewFolder={(folder) => {
                        fileClient.createFolder(folder.path + '/' + folder.name.trim())
                            .then(fetchFolder)
                            .catch(() => { })
                    }}
                    onFileClick={props.onFileClick}
                    onFolderClick={props.onFolderClick}
                    onStreamClick={props.onStreamClick}
                    onItemClick={props.onItemClick}
                    onItemDeletion={(item) => {
                        switch (item.type) {
                            case 'folder':
                                fileClient.deleteFolder(item.path)
                                    .then(fetchFolder)
                                    .catch(() => { })
                                break;
                            case 'file':
                                fileClient.deleteFile(item.path, item.name)
                                    .then(fetchFolder)
                                    .catch(() => { })
                                break;
                            case 'stream':
                                break;
                        }
                    }}
                    onStructureChange={fetchFolder}
                />
                :
                <InlineLoader />
            }
        </>
    );
}


const folderToFileSystemItems = (folder: Folder): ExplorerItem[] => {
    const items: ExplorerItem[] = []

    folder.folders.forEach(childFolder => {
        items.push({
            type: 'folder',
            name: childFolder.folderName,
            path: childFolder.path,
            children: folderToFileSystemItems(childFolder)
        })
    })
    folder.files.forEach(file => {
        items.push({
            type: 'file',
            name: file.fileName,
            path: file.path,
            size: file.size,
            key: file.key,
            downloadLink: file.fileDownloadUri
        })
    })
    return items
}


export default FileExplorer;