import { Typography } from '@mui/material';
import * as React from 'react';

export interface IPage404Props {
}

const Page404: React.FunctionComponent<IPage404Props> = (props: IPage404Props) => {
    return (
        <><Typography variant="h2">
            Sparky konnte nicht finden :-(
        </Typography></>
    );
}

export default Page404;