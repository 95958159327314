import { createContext, useContext } from "react";
import ApiClient, { ClientOptions } from "./ApiClient";
import { User } from "./model/User";
import { JwtToken } from "./model/JwtToken";
import { useNotificationContext } from "../contexts/NotificationContext";

export default class AuthApiClient extends ApiClient {
    public signup = async (body: { idProvider: string, username: string, password?: string, email: string, temporaryPassword?: boolean, sendMailToUser?: boolean }, options?: ClientOptions): Promise<User> => {
        return this.post('/auth/signup', { body, ...options });
    }

    public signin = async (principal: string, password: string, options?: ClientOptions): Promise<JwtToken> => {
        return this.post('/auth/signin', { body: { principal, password }, ...options, preventTokenRefresh: true, preventAuthorization: true })
    }

    public refresh = async (refreshToken: string, options?: ClientOptions): Promise<JwtToken> => {
        return this.post('/auth/refreshtoken', { body: { refreshToken }, preventNotification: true, ...options, preventTokenRefresh: true, preventAuthorization: true });
    }

    public isAuthorized = async (options?: ClientOptions) => {
        return this.get('/auth/isauthorized', { preventNotification: true, ...options });
    }

    public verifyRegistrationLink = (registrationId: string, options?: ClientOptions) => {
        return this.get(`/auth/registration/${registrationId}/verify`, { preventNotification: true, ...options, preventAuthorization: true })
    }

    public completeRegistration = (registrationId: string, body: { password?: string, temporaryPassword?: boolean }, options?: ClientOptions) => {
        return this.get(`/auth/registration/${registrationId}/complete-registration`, { body, ...options, preventAuthorization: true })
    }

    public requestPasswordReset = (email: string, options?: ClientOptions) => {
        return this.post(`/auth/password-reset/request`, { body: { email }, ...options, preventAuthorization: true })
    }

    public getPasswordResetProcess = (processId: string, options?: ClientOptions): Promise<User> => {
        return this.get(`/auth/password-reset/${processId}`, { ...options, preventAuthorization: true })
    }
    public resetPassword = (processId: string, password: string, options?: ClientOptions) => {
        return this.post(`/auth/password-reset/${processId}`, { body: { password }, ...options, preventAuthorization: true })
    }

}

export const AuthApiClientContext = createContext<AuthApiClient>(new AuthApiClient())

export const useAuthApiClient = (defaultConfig?: ClientOptions) => {
    const context = useContext(AuthApiClientContext)
    context.notificationCtx = useNotificationContext()
    if (defaultConfig) {
        context.defaultConfig = defaultConfig
    }
    return context
}

