import React, { useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, Button, Typography, Grid,
    Fab, ListItemText, FormControl, TextField, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material';
import DialogHeader from '../../../../../shared/components/dialogs/DialogHeader';
import ConnectionSelect, { SourceOption } from "../../../../../shared/components/dialogs/ConnectionSelect";
import { SiApachehadoop } from "react-icons/si";
import { TbPlugConnected } from "react-icons/tb";
import { FaAws } from "react-icons/fa6";
import { PiFileCloud } from "react-icons/pi";
import { SiPostgresql } from "react-icons/si";
import { GrOracle } from "react-icons/gr";
import { SiApachekafka } from "react-icons/si";
import { TbApi } from "react-icons/tb";
import { FileItem } from '../../../../common/Explorer';
import { Folder } from '@mui/icons-material';
import {WriteNodeModel} from "../WriteNodeModel";
import CustomHeaderCell from "../../../../../shared/components/table/CustomHeaderCell";
import CreateFileDialog from '../../../../dialogs/CreateFileDialog';

// todo: get this from backend
const availableConenctions: SourceOption[] = [
    {
        id: "1",
        name: "My User-Space",
        connectionType: "File: S3-Bucket",
        icon: <FaAws size={30} />,
    }, {
        id: "2",
        name: "Company-Space",
        connectionType: "File: S3-Bucket",
        icon: <FaAws size={30} />,
    }, {
        id: "300",
        name: "Hadoop Example",
        connectionType: "File: HDFS",
        icon: <SiApachehadoop size={30} />,
    }, {
        id: "400",
        name: "SFTP: Example Host",
        connectionType: "File: SFTP",
        icon: <PiFileCloud size={30} />,
    }, {
        id: "500",
        name: "Store Munich",
        connectionType: "Database: Postgres",
        icon: <SiPostgresql size={25} />,
    }, {
        id: "600",
        name: "Alle Lottozahlen",
        connectionType: "Database: Oracle",
        icon: <GrOracle size={25} />,
    }, {
        id: "700",
        name: "New Contract Event in Europe",
        connectionType: "Stream: Kafka",
        icon: <SiApachekafka size={25} />,
    }, {
        id: "800",
        name: "Books on sale",
        connectionType: "API: JSON",
        icon: <TbApi size={25} />,
    },
]

interface WriteNodeSettingsProps {
    open: boolean;
    handleClose: () => void;
    nodeModel: WriteNodeModel
    selectedConnectionId?: string
    comment: string
    setComment: React.Dispatch<React.SetStateAction<string>>
    setSettingsSummary: React.Dispatch<React.SetStateAction<string>>
}

const WriteNodeSettings: React.FC<WriteNodeSettingsProps> = (props: WriteNodeSettingsProps) => {

    const [inputCols, setInputCols] = useState<string[]>([]);
    const [selectedFileInfos, setSelectedFileInfos] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState<string>("");
    const [selectedSourceOption, setSelectedSourceOption] = useState<SourceOption | undefined>(undefined)
    const dialogContentRef = React.useRef<HTMLDivElement>(null);
    const [selectFileOpen, setSelectFileOpen] = useState(false)

    // const fileClient = useFileManagementApiClient()

    // dont propagate events to componente below
    React.useEffect(() => {
        if (!props.open) return
        const dialogContent = dialogContentRef.current;
        const preventScroll = (e: WheelEvent) => {
            // e.preventDefault();
            e.stopPropagation();
        };

        const attachEventListener = () => {
            if (dialogContent) {
                dialogContent.addEventListener('wheel', preventScroll);
            }
        };

        if (props.open) {
            // Use setTimeout to ensure the dialog content is rendered before attaching the event listener
            setTimeout(attachEventListener, 0);
        }

        return () => {
            if (dialogContent) {
                dialogContent.removeEventListener('wheel', preventScroll);
            }
        };
    }, [props.open, dialogContentRef]);

    React.useEffect(() => {
        if (props.selectedConnectionId) {
            const so = availableConenctions.find(connection => connection.id === props.selectedConnectionId);
            if (so) {
                setSelectedSourceOption(so)
            } else {
                console.error("WriteNodeSettings: Cannot find Connection with ID " + props.selectedConnectionId)
            }
        }
    }, [props.selectedConnectionId]);

    const onConnectionSelected = (sourceOption: SourceOption) => {
        // todo: fetch objects from this connection, show them here to select object (eg file) by user
        setSelectedSourceOption(sourceOption)
    }

    const onApply = () => {
        if (selectedSourceOption) {
            props.nodeModel.setSelectedConnectionId(selectedSourceOption.id)
        }
        props.nodeModel.setSelectedTargetElement(selectedFile)

        props.nodeModel.setComment(props.comment)

        onClose()
    }

    const onClose = () => {
        props.handleClose && props.handleClose()
    }

    const onSourceElementSelected = (file: FileItem) => {
        // todo: handle other selected objects, too (like database tables, API endpoints, stream JSONs, ...)
        if (file.type === 'file') {
            setSelectedFile(file.path + "" + file.name)
            setSelectedFileInfos("loading...")
            props.setSettingsSummary(file.name)
        }
    };

    React.useEffect(()=>{
        setInputCols(props.nodeModel.inputColumns)
        // eslint-disable-next-line
    }, [props.nodeModel.inputColumns])

    // used to re-load the filter-settings after the stream was reconstructed from file
    React.useEffect(()=>{
        // todo
        // eslint-disable-next-line
    }, [props.nodeModel.selectedConnectionId])
    React.useEffect(()=>{
        setSelectedFile(props.nodeModel.selectedTargetElement)
        // eslint-disable-next-line
    }, [props.nodeModel.selectedTargetElement])
    React.useEffect(()=>{
        props.setComment(props.nodeModel.comment)
        // eslint-disable-next-line
    }, [props.nodeModel.comment])

    return (
        <>
            <Dialog open={props.open} onClose={onClose}
                maxWidth="lg"
                scroll="paper"
                PaperProps={{ sx: { backgroundColor: "white" } }}
                disableEnforceFocus
                fullWidth>
                <DialogHeader
                    headline={"Write to "}
                    closeDialog={onClose}
                />
                <DialogContent ref={dialogContentRef}>

                    <div style={{ display: 'flex', flexDirection: 'column', height: '60vh' }}>
                        {inputCols.length > 0 ? (
                            <>
                                <div>
                                    <Typography variant="body1">&nbsp;</Typography>
                                </div>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {inputCols.map((field, index) => (
                                                    <TableCell key={index}>
                                                        <CustomHeaderCell
                                                            mainText={field}
                                                            subText={'Unknown'} // Replace with actual type or 'Unknown'
                                                        />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.from({ length: 100 }, (row, rowIndex) => (
                                                <TableRow key={rowIndex}>
                                                    {inputCols.map((field, lineIndex) => (
                                                        <TableCell key={lineIndex}>{rowIndex + " " + lineIndex}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        ) : (
                            <></>
                        )}

                    </div>

                    <Grid container sx={{ marginTop: '20px', marginLeft: '20px', marginBottom: '10px' }}>
                        <Grid item xs={4} container>
                            <ConnectionSelect
                                selected={selectedSourceOption}
                                availableOptions={availableConenctions}
                                icon={(selectedSourceOption ? selectedSourceOption.icon : <TbPlugConnected size={30} />)}
                                onSelect={onConnectionSelected}
                            />
                        </Grid>
                        {/* todo add more selectors for different connections */}
                        {selectedSourceOption?.connectionType.startsWith("File: ") &&
                            <Grid item xs={8}>
                                <Grid container>
                                    <Grid item xs={2} container justifyContent="center" alignItems="center">
                                        <Fab onClick={() => setSelectFileOpen(true)} color="primary"
                                             sx={{
                                                 display: 'flex',
                                                 alignItems: 'center',
                                                 justifyContent: 'center',
                                             }}
                                        >
                                            <Folder />
                                        </Fab>
                                    </Grid>
                                    <Grid item xs={10} sx={{alignItems: "left", paddingLeft: "0px"}}>
                                        <Typography variant="h6">
                                            <ListItemText
                                                primary={(selectedFile ? selectedFile : "← Please select an object ")}
                                                secondary={(selectedFileInfos ? selectedFileInfos : "")}
                                            />
                                        </Typography>
                                    </Grid>

                                    <CreateFileDialog
                                        open={selectFileOpen}
                                        onClose={() => setSelectFileOpen(false)}
                                        onConfirm={onSourceElementSelected}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Grid>


                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                label="Node Comment"
                                value={props.comment}
                                onChange={(e) => props.setComment(e.target.value)}
                                multiline
                                rows={2}
                            />
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onApply}>Apply</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default WriteNodeSettings;