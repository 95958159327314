import ApiClient, { ClientOptions } from "./ApiClient";
import { createContext, useContext } from "react";
import { useNotificationContext } from "../contexts/NotificationContext";
import { ClusterStatus, ClusterSummary } from "./model/Cluster";

export default class ClusterApiClient extends ApiClient {
    public getClusterStatus = async (clusterId: string, options?: ClientOptions): Promise<ClusterStatus> => {
        return this.get(`/cluster/status/${clusterId}`, options);
    }
    public getAllClusters = async (options?: ClientOptions): Promise<Array<ClusterSummary>> => {
        return this.get(`/cluster/clusters`, options);
    }

    public deleteCluster = async (clusterId: string, options?: ClientOptions): Promise<void> => {
        return this.delete(`/cluster/${clusterId}`, options);
    }

    public createCluster = async (options?: ClientOptions): Promise<string> => {
        return this.post('/cluster/', options);
    }
    public getClusterLogs = async (clusterId: string, options?: ClientOptions): Promise<string> => {
        return this.get(`/cluster/${clusterId}/cluster-logs`, options);
    }
    
    public getSparkLogs = async (clusterId: string, options?: ClientOptions): Promise<string> => {
        return this.get(`/cluster/${clusterId}/spark-logs`, options);
    }
}


export const ClusterApiClientContext = createContext<ClusterApiClient>(new ClusterApiClient())

export const useClusterApiClient = (defaultConfig?: ClientOptions) => {
    const context = useContext(ClusterApiClientContext)
    context.notificationCtx = useNotificationContext()
    if (defaultConfig) {
        context.defaultConfig = defaultConfig
    }
    return context
}