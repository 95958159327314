import * as React from 'react';
import { Role } from '../../clients/model/Role';
import { Navigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useUserContext } from '../../contexts/UserContext';


export interface IProtectedProps {
    roles?: Role[]
}

const Protected: React.FunctionComponent<React.PropsWithChildren<IProtectedProps>> = (props: React.PropsWithChildren<IProtectedProps>) => {
    const userContext = useUserContext();

    if (userContext.isLoggedIn()) {
        if (props.roles && userContext.user && !userContext.userHasAccess(...props.roles)) {
            return <><Typography variant="h4">
                I&apos;m sorry, but you don&apos;t have access to this resource! Please contact your administrator for further information.
            </Typography></>
        } else {
            return <>{props.children}</>
        }
    }
    // if we aren't logged in, we want to give the login page the correct redirect url
    // this builds the url from the specific components:
    // href = http://localhost:3000/something?query=123#somethingelse
    // origin = http://localhost:3000
    // domain = localhost: 3000
    // pathname = something
    // search = query = 123
    // hash = somethingelse
    // Some docu to read: https://developer.mozilla.org/en-US/docs/Web/API/Window/location
    const redirectUrl = window.location.href.replace(window.location.origin, "")
    return (
        <>
            <Navigate replace to={'/login?redirect=' + redirectUrl}></Navigate>
        </>
    );
}

export default Protected;