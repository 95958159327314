import * as React from 'react';
import {GroupByNodeModel} from './GroupByNodeModel';
import {DiagramEngine, PortModelAlignment, PortWidget} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import { S } from '../../port/SimplePortWidget';
import {NodeTypeEnum} from "../NodeTypeEnum";
import DefaultNodeDecoration from "../DefaultNodeDecoration";

export interface DiamondNodeWidgetProps {
    node: GroupByNodeModel;
    engine: DiagramEngine;
    width: number;
    height: number;
}

export class GroupByNodeWidget extends React.Component<DiamondNodeWidgetProps> {
    render() {
        return (
            <div
                className={'diamond-node'}
                style={{
                    position: 'relative',
                    width: this.props.width,
                    height: this.props.height
                }}
            >
                <svg width="303" height="179" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" overflow="hidden">
                    <defs>
                        <filter id="fx0-nodeGroupBy" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                                primitiveUnits="userSpaceOnUse">
                            <feComponentTransfer colorInterpolationFilters="sRGB">
                                <feFuncR type="discrete" tableValues="0 0"/>
                                <feFuncG type="discrete" tableValues="0 0"/>
                                <feFuncB type="discrete" tableValues="0 0"/>
                                <feFuncA type="linear" slope="0.4" intercept="0"/>
                            </feComponentTransfer>
                            <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                        </filter>
                        <filter id="fx1-nodeGroupBy" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                                primitiveUnits="userSpaceOnUse">
                            <feComponentTransfer colorInterpolationFilters="sRGB">
                                <feFuncR type="discrete" tableValues="0 0"/>
                                <feFuncG type="discrete" tableValues="0 0"/>
                                <feFuncB type="discrete" tableValues="0 0"/>
                                <feFuncA type="linear" slope="0.4" intercept="0"/>
                            </feComponentTransfer>
                            <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                        </filter>
                        <clipPath id="clip2-nodeGroupBy">
                            <rect x="468" y="132" width="303" height="179"/>
                        </clipPath>
                        <clipPath id="clip3-nodeGroupBy">
                            <path
                                d="M639.006 140 639.006 144.142 639.006 157.005 639.006 160.709C639.006 162.996 637.154 164.851 634.869 164.851L627.916 164.851 627.916 168.14C627.916 170.428 626.063 172.282 623.778 172.282L616.826 172.282 616.826 176.092C616.826 178.379 614.973 180.233 612.688 180.233L598 180.233 598 198.104 634.869 198.104C637.154 198.104 639.006 199.958 639.006 202.246L639.006 218.813C639.006 221.1 637.154 222.955 634.869 222.955L627.916 222.955 627.916 226.764C627.916 229.051 626.063 230.906 623.778 230.906L616.826 230.906 616.826 234.715C616.826 237.003 614.973 238.857 612.688 238.857L598 238.857 598 256.727 634.869 256.727C637.154 256.727 639.006 258.582 639.006 260.869L639.006 277.436C639.006 279.724 637.154 281.578 634.869 281.578L627.916 281.578 627.916 285.387C627.916 287.675 626.063 289.529 623.778 289.529L616.826 289.529 616.826 293.858C616.826 296.146 614.973 298 612.688 298L731.691 298C746.221 298 758 286.21 758 271.666L758 166.334C758 151.79 746.221 140 731.691 140ZM468 132 771 132 771 311 468 311Z"
                                fillRule="evenodd" clipRule="evenodd"/>
                        </clipPath>
                        <clipPath id="clip4-nodeGroupBy">
                            <path
                                d="M501.348 140C486.796 140 475 151.79 475 166.334L475 271.666C475 286.21 486.796 298 501.348 298L562.407 298 581.37 298 596.932 298 611.642 298C613.931 298 615.786 296.146 615.786 293.858L615.786 289.529 622.749 289.529C625.038 289.529 626.893 287.675 626.893 285.387L626.893 281.578 633.856 281.578C636.145 281.578 638 279.724 638 277.436L638 260.869C638 258.582 636.145 256.727 633.856 256.727L596.932 256.727 596.932 238.857 611.642 238.857C613.931 238.857 615.786 237.003 615.786 234.715L615.786 230.906 622.749 230.906C625.038 230.906 626.893 229.051 626.893 226.764L626.893 222.955 633.856 222.955C636.145 222.955 638 221.1 638 218.813L638 202.246C638 199.958 636.145 198.104 633.856 198.104L596.932 198.104 596.932 180.233 611.642 180.233C613.931 180.233 615.786 178.379 615.786 176.092L615.786 172.282 622.749 172.282C625.038 172.282 626.893 170.428 626.893 168.14L626.893 164.851 633.856 164.851C636.145 164.851 638 162.996 638 160.709L638 144.142C638 141.854 636.145 140 633.856 140L596.932 140 596.932 140 581.37 140 562.407 140ZM468 132 771 132 771 311 468 311Z"
                                fillRule="evenodd" clipRule="evenodd"/>
                        </clipPath>
                    </defs>
                    <g clipPath="url(#clip2-nodeGroupBy)" transform="translate(-468 -132)">
                        <g clipPath="url(#clip3-nodeGroupBy)">
                            <g filter="url(#fx0-nodeGroupBy)" transform="translate(590 132)">
                                <g>
                                    <path
                                        d="M51.8349 10.8284 144.52 10.8284C159.05 10.8284 170.828 22.6185 170.828 37.1623L170.828 142.495C170.828 157.038 159.05 168.828 144.52 168.828L25.516 168.828C27.8014 168.828 29.6539 166.974 29.6539 164.687L29.6539 160.358 36.6065 160.358C38.8918 160.358 40.7444 158.503 40.7444 156.216L40.7444 152.407 47.697 152.407C49.9823 152.407 51.8349 150.552 51.8349 148.265L51.8349 131.698C51.8349 129.41 49.9823 127.556 47.697 127.556L10.8284 127.556 10.8284 109.685 25.516 109.685C27.8014 109.685 29.6539 107.831 29.6539 105.544L29.6539 101.734 36.6065 101.734C38.8918 101.734 40.7444 99.8799 40.7444 97.5924L40.7444 93.7831 47.697 93.7831C49.9823 93.7831 51.8349 91.9287 51.8349 89.6412L51.8349 73.0742C51.8349 70.7867 49.9823 68.9323 47.697 68.9323L10.8284 68.9323 10.8284 51.0619 25.516 51.0619C27.8014 51.0619 29.6539 49.2075 29.6539 46.92L29.6539 43.1108 36.6065 43.1108C38.8918 43.1108 40.7444 41.2564 40.7444 38.9689L40.7444 35.6793 47.697 35.6793C49.9823 35.6793 51.8349 33.8249 51.8349 31.5374L51.8349 27.833 51.8349 14.9704Z"
                                        stroke="#FFFFFF" strokeWidth="8" strokeMiterlimit="8" fill="#D3CC84"
                                        fillRule="evenodd"/>
                                </g>
                            </g>
                        </g>
                        <path
                            d="M639.006 140 731.691 140C746.221 140 758 151.79 758 166.334L758 271.666C758 286.21 746.221 298 731.691 298L612.688 298C614.973 298 616.826 296.146 616.826 293.858L616.826 289.529 623.778 289.529C626.063 289.529 627.916 287.675 627.916 285.387L627.916 281.578 634.869 281.578C637.154 281.578 639.006 279.724 639.006 277.436L639.006 260.869C639.006 258.582 637.154 256.727 634.869 256.727L598 256.727 598 238.857 612.688 238.857C614.973 238.857 616.826 237.003 616.826 234.715L616.826 230.906 623.778 230.906C626.063 230.906 627.916 229.051 627.916 226.764L627.916 222.955 634.869 222.955C637.154 222.955 639.006 221.1 639.006 218.813L639.006 202.246C639.006 199.958 637.154 198.104 634.869 198.104L598 198.104 598 180.233 612.688 180.233C614.973 180.233 616.826 178.379 616.826 176.092L616.826 172.282 623.778 172.282C626.063 172.282 627.916 170.428 627.916 168.14L627.916 164.851 634.869 164.851C637.154 164.851 639.006 162.996 639.006 160.709L639.006 157.005 639.006 144.142Z"
                            stroke="#FFFFFF" strokeWidth="8" strokeMiterlimit="8" fill="#D3CC84" fillRule="evenodd"/>
                        <g clipPath="url(#clip4-nodeGroupBy)">
                            <g filter="url(#fx1-nodeGroupBy)" transform="translate(467 132)">
                                <g>
                                    <path
                                        d="M136.652 0 75.5931 0 56.6305 0 41.0677 0 41.0677 0.000104648 4.14408 0.000104648C1.85535 0.000104648 0 1.85447 0 4.14197L0 20.709C0 22.9965 1.85535 24.8509 4.14408 24.8509L11.1071 24.8509 11.1071 28.1405C11.1071 30.428 12.9624 32.2823 15.2512 32.2823L22.2141 32.2823 22.2141 36.0916C22.2141 38.3791 24.0694 40.2335 26.3582 40.2335L41.0677 40.2335 41.0677 58.1039 4.14408 58.1039C1.85535 58.1039 0 59.9583 0 62.2458L0 78.8128C0 81.1003 1.85535 82.9546 4.14408 82.9546L11.1071 82.9546 11.1071 86.7639C11.1071 89.0514 12.9624 90.9058 15.2512 90.9058L22.2141 90.9058 22.2141 94.7151C22.2141 97.0026 24.0694 98.8569 26.3582 98.8569L41.0677 98.8569 41.0677 116.727 4.14408 116.727C1.85535 116.727 0 118.582 0 120.869L0 137.436C0 139.724 1.85535 141.578 4.14408 141.578L11.1071 141.578 11.1071 145.387C11.1071 147.675 12.9624 149.529 15.2512 149.529L22.2141 149.529 22.2141 153.858C22.2141 156.146 24.0694 158 26.3582 158L41.0677 158 41.0677 158 56.6305 158 75.5931 158 136.652 158C151.204 158 163 146.21 163 131.666L163 26.3338C163 11.7901 151.204 0 136.652 0Z"
                                        stroke="#FFFFFF" strokeWidth="8" strokeMiterlimit="8" fill="#D3CC84"
                                        fillRule="evenodd" transform="matrix(-1 0 0 1 173.828 10.8284)"/>
                                </g>
                            </g>
                        </g>
                        <path
                            d="M136.652 0 75.5931 0 56.6305 0 41.0677 0 41.0677 0.000104648 4.14408 0.000104648C1.85535 0.000104648 0 1.85447 0 4.14197L0 20.709C0 22.9965 1.85535 24.8509 4.14408 24.8509L11.1071 24.8509 11.1071 28.1405C11.1071 30.428 12.9624 32.2823 15.2512 32.2823L22.2141 32.2823 22.2141 36.0916C22.2141 38.3791 24.0694 40.2335 26.3582 40.2335L41.0677 40.2335 41.0677 58.1039 4.14408 58.1039C1.85535 58.1039 0 59.9583 0 62.2458L0 78.8128C0 81.1003 1.85535 82.9546 4.14408 82.9546L11.1071 82.9546 11.1071 86.7639C11.1071 89.0514 12.9624 90.9058 15.2512 90.9058L22.2141 90.9058 22.2141 94.7151C22.2141 97.0026 24.0694 98.8569 26.3582 98.8569L41.0677 98.8569 41.0677 116.727 4.14408 116.727C1.85535 116.727 0 118.582 0 120.869L0 137.436C0 139.724 1.85535 141.578 4.14408 141.578L11.1071 141.578 11.1071 145.387C11.1071 147.675 12.9624 149.529 15.2512 149.529L22.2141 149.529 22.2141 153.858C22.2141 156.146 24.0694 158 26.3582 158L41.0677 158 41.0677 158 56.6305 158 75.5931 158 136.652 158C151.204 158 163 146.21 163 131.666L163 26.3338C163 11.7901 151.204 0 136.652 0Z"
                            stroke="#FFFFFF" strokeWidth="8" strokeMiterlimit="8" fill="#D3CC84" fillRule="evenodd"
                            transform="matrix(-1 0 0 1 638 140)"/>
                    </g>
                </svg>

                <DefaultNodeDecoration
                    nodeType={NodeTypeEnum.NODE_GROUP_BY}
                    comment={""}
                    textOnNode={"PLZ, Gender"}
                />

                <PortWidget
                    style={{
                        top: this.props.height / 2 - 22,
                        left: -25,
                        position: 'absolute'
                    }}
                    port={this.props.node.getPort('input') || new SparkyELTPortModel(PortModelAlignment.LEFT, 'input')}
                    engine={this.props.engine}
                >
                    <S.Port/>
                </PortWidget>
                <PortWidget
                    style={{
                        top: this.props.height / 2 - 22,
                        left: this.props.width - 14,
                        position: 'absolute'
                    }}
                    port={this.props.node.getPort('output') || new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output')}
                    engine={this.props.engine}
                >
                    <S.Port/>
                </PortWidget>

            </div>
        );
    }
}