import ApiClient, { ClientOptions } from "./ApiClient";
import { createContext, useContext } from "react";
import { useNotificationContext } from "../contexts/NotificationContext";
import { FileUploadResponse, Folder } from "./model/Folder";

export default class FileManagementApiClient extends ApiClient {
    public getFolderStructure = async (options?: ClientOptions): Promise<Folder> => {
        return this.get(`/files/folder-structure`, options);
    }

    public createFolder = async (path: string, options?: ClientOptions): Promise<void> => {
        return this.post(`/files/folder`, { body: { path }, ...options });
    }
    public deleteFolder = async (path: string, options?: ClientOptions): Promise<void> => {
        return this.delete(`/files/folder`, { body: { path }, ...options });
    }

    public generateUploadLink = async (path: string, fileName: string, options?: ClientOptions): Promise<FileUploadResponse> => {
        return this.post(`/files/generate-upload-link`, { body: { path, fileName }, ...options });
    }

    public deleteFile = async (path: string, fileName: string, options?: ClientOptions): Promise<void> => {
        return this.delete(`/files/file`, { body: { path, fileName }, ...options });
    }

    public getFilePreview = async (path: string, fileName: string, options?: ClientOptions): Promise<void> => {
        return this.post(`/nodes/read/file-preview`, { body: { path, fileName }, ...options });
    }
}

export const FileManagementApiClientContext = createContext<FileManagementApiClient>(new FileManagementApiClient())

export const useFileManagementApiClient = (defaultConfig?: ClientOptions) => {
    const context = useContext(FileManagementApiClientContext)
    context.notificationCtx = useNotificationContext()
    if (defaultConfig) {
        context.defaultConfig = defaultConfig
    }
    return context
}