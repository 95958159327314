import { NodeModel, NodeModelGenerics, PortModelAlignment } from '@projectstorm/react-diagrams';
import { SparkyELTPortModel } from '../../port/SparkyELTPortModel';

export interface DiamondNodeModelGenerics {
    PORT: SparkyELTPortModel;
}

export interface ReadNodeProperties {
    slectedConnectionId: string;
    selectedSourceElement: string;
}

// Extend NodeModelGenerics to include custom properties
interface CustomNodeModelGenerics extends NodeModelGenerics {
    PROPERTIES: ReadNodeProperties;
}

export class ReadNodeModel extends NodeModel<CustomNodeModelGenerics & DiamondNodeModelGenerics> {

    // Define the custom properties
    slectedConnectionId: string;
    selectedSourceElement: string;
    comment: string;
    inputColumns: string[];
    outputColumns: string[];

    constructor(slectedConnectionId: string, selectedSourceElement: string) {
        super({
            type: 'Node_READ'
        });

        // Initialize custom properties
        this.slectedConnectionId = slectedConnectionId;
        this.selectedSourceElement = selectedSourceElement;
        this.comment = "";
        this.inputColumns = []
        this.outputColumns = []

        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output'));
    }

    // Override the serialize method to include custom properties
    serialize(): any {
        return {
            ...super.serialize(),
            slectedConnectionId: this.slectedConnectionId,
            selectedSourceElement: this.selectedSourceElement,
            comment: this.comment
        };
    }

    // Override the deserialize method to restore custom properties
    deserialize(event: any): void {
        super.deserialize(event);
        this.slectedConnectionId = event.data.slectedConnectionId;
        this.selectedSourceElement = event.data.selectedSourceElement;
        this.comment = event.data.comment;
    }

    calculateOutputCols(): string[] {
        // todo: implement here. At the moment done in Demo.tsx
        return this.getOutputColumns();
    }

    getSelectedConnectionId(): string {
        return this.slectedConnectionId;
    }

    setSelectedConnectionId(value: string): void {
        this.slectedConnectionId = value;
    }

    getSelectedSourceElement(): string {
        return this.selectedSourceElement;
    }

    setSelectedSourceElement(value: string): void {
        this.selectedSourceElement = value;
    }

    getInputColumns(): string[] {
        return this.inputColumns
    }

    setInputColumns(cols: string[]): void {
        this.inputColumns = cols
    }

    getOutputColumns(): string[] {
        return this.outputColumns
    }

    setOutputColumns(cols: string[]): void {
        this.outputColumns = cols
    }

    getComment(): string {
        return this.comment
    }

    setComment(comment: string): void {
        this.comment = comment
    }


}