import { Badge, Grid, IconButton, MenuItem, Paper, Select, Tooltip } from '@mui/material';
import * as React from 'react';
import ClusterStatusIcon from './components/ClusterStatusIcon';
import { ClusterRunningState } from '../../clients/model/Cluster';
import { Article, Error, PlayArrow, Stop } from '@mui/icons-material';
import { useClusterContext } from '../../contexts/ClusterContext';
import { useState } from 'react';
import ClusterLogDialog from './components/ClusterLogDialog';

export interface IClusterViewProps {
}

const ClusterView: React.FunctionComponent<IClusterViewProps> = (props: IClusterViewProps) => {
    const clusterCtx = useClusterContext()

    const [logsOpen, setLogsOpen] = useState(false)

    return (
        <>
            <Paper
                sx={{
                    position: 'absolute',
                    left: "50px",
                    bottom: "20px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '30vw',
                }}
            >
                <Grid container sx={{ padding: "0.5em" }}>
                    <Grid item xs={12} container spacing={2} alignItems="center">
                        <Grid item xs={6} md={8}>
                            <Select
                                fullWidth
                                value={clusterCtx.clusterId ?? "----"}
                                onChange={(e) => clusterCtx.setCluster(e.target.value)}
                            >
                                {clusterCtx.availableCluster && clusterCtx.availableCluster.length > 0 ? clusterCtx.availableCluster.map(c => <MenuItem value={c.id}>
                                    {c.id}
                                </MenuItem>)
                                    : <MenuItem value="----"><i>no cluster available</i></MenuItem>}
                            </Select>
                        </Grid>
                        <Grid item xs={6} md={4} container alignItems="center" justifyContent="space-evenly">
                            <Grid item>
                                {clusterCtx.clusterId && clusterCtx.clusterStatus?.state !== ClusterRunningState.TERMINATED ?
                                    <Tooltip title="Stop cluster">
                                        <IconButton onClick={clusterCtx.stopCluster} color="error">
                                            <Stop />
                                        </IconButton>
                                    </Tooltip>
                                    : <Tooltip title="Start new cluster">
                                        <IconButton onClick={clusterCtx.startCluster} color="success">
                                            <PlayArrow />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                            <Grid item>
                                <ClusterStatusIcon status={clusterCtx.clusterStatus} />
                            </Grid>
                            <Grid item>
                                <Tooltip title="View Cluster Logs">
                                    <Badge badgeContent={(clusterCtx.clusterLogsChanged || clusterCtx.sparkLogsChanged) && <Error color='error' />} >
                                        <IconButton
                                            disabled={!clusterCtx.clusterId}
                                            onClick={() => setLogsOpen(true)} color="info">
                                            <Article />
                                        </IconButton>
                                    </Badge>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <ClusterLogDialog open={logsOpen} onClose={() => setLogsOpen(false)} />
        </>
    );
}

export default ClusterView;