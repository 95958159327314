import * as React from "react";
import {createContext, useContext} from "react";
import Cookies from "universal-cookie";
import {useAuthApiClient} from "../clients/AuthApiClient";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {IdProvider} from "../clients/ApiClient";
import { JwtToken } from "../clients/model/JwtToken";
import { Role } from "../clients/model/Role";
import { User } from "../clients/model/User";

export const EXPIRY_TIME = 60 * 60 * 1000; // 1 hour * 60 minutes * 60 seconds * 1000 millis
// export const EXPIRY_TIME = 10 * 1000 // 1 hour * 60 minutes * 60 seconds * 1000 millis

export const EXPIRY_OFFSET = EXPIRY_TIME / 10; // refresh token if 90% of the time is gone

export const getExpiryDateFromNow = () =>
    new Date(new Date().getTime() + EXPIRY_TIME);

export const checkAccessforUser = (user?: User, roles?: Role[]) => {
    if (!user) {
        return false;
    }
    for (let i = 0; i < (roles?.length ?? 0); i++) {
        for (let j = 0; j < (user.roles?.length ?? 0); j++) {
            if (roles![i] === user.roles![j]) {
                return true;
            }
        }
    }
    return false;
};

export interface UserContext {
    user?: User;

    setTokenFromCookies(): void;
    isLoggedIn(): boolean;
    login(token: JwtToken): void;
    loginGoogle(googleToken: string, profilePicture?: string): void;
    loginFacebook(facebookToken: string, profilePicture?: string): void;
    logout(reload?: boolean): void;
    setCurrentUser(user: User): void;
    refreshToken(): void;
    verifyToken(): Promise<boolean>;
    userHasAccess(...roles: Role[]): boolean;
}

const defaultUserContext: UserContext = {
    setTokenFromCookies: function (): void {
        throw new Error("Function not implemented.");
    },
    isLoggedIn: function (): boolean {
        throw new Error("Function not implemented.");
    },
    login: function (_token: JwtToken): void {
        throw new Error("Function not implemented.");
    },
    loginGoogle: function(_googleToken: string, _profilePicture?: string): void {
        throw new Error("Function not implemented.");
    },
    loginFacebook: function(_facebookToken: string, _profilePicture?: string): void {
        throw new Error("Function not implemented.");
    },
    logout: function (): void {
        throw new Error("Function not implemented.");
    },
    verifyToken: function (): Promise<boolean> {
        throw new Error("Function not implemented.");
    },
    setCurrentUser: function (user: User): void {
        throw new Error("Function not implemented.");
    },
    userHasAccess: function (...roles: Role[]): boolean {
        throw new Error("Function not implemented.");
    },
    refreshToken: function (): void {
        throw new Error("Function not implemented.");
    },
};

export const UserContextHolder = createContext<UserContext>(defaultUserContext);

export const useUserContext = () => {
    return useContext(UserContextHolder);
};

export interface IUserContextProps {}

export const UserContextProvider: React.FunctionComponent<
    React.PropsWithChildren<IUserContextProps>
> = (props: React.PropsWithChildren<IUserContextProps>) => {
    const authApiClient = useAuthApiClient();
    const cookies = new Cookies();

    const [user, setUser] = React.useState<User>();

    const refreshTokenInterval = React.useRef<NodeJS.Timeout>();

    const getRefreshToken = () => {
        return cookies.get("refresh-token");
    };
    const setRefreshToken = (refreshToken: string) => {
        cookies.set("refresh-token", refreshToken, {
            path: "/",
            maxAge: EXPIRY_TIME * 24,
            sameSite: "strict",
        });
    };
    const getToken = () => {
        return cookies.get("token");
    };
    const setToken = (token: string) => {
        cookies.set("token", token, {
            path: "/",
            expires: getExpiryDateFromNow(),
            maxAge: EXPIRY_TIME,
            sameSite: "strict",
        });
    };
    
    const setIdProvider = (idProvider: string) => {
        cookies.set("x-id-provider", idProvider, {
            path: "/",
            sameSite: "strict",
        });
    };
    
    const setProfilePicture = (profilePicture: string) => {
        cookies.set("bb-profile-picture", profilePicture, {
            path: "/",
            sameSite: "strict",
        });
    };

    /* const setCookiesFromToken = (jwtToken: JwtToken) => {
        setToken(jwtToken.token);
        setRefreshToken(jwtToken.refreshToken);
    };*/

    const setTokenFromCookies = () => {
        const token = cookies.get("token");
        const refreshToken = cookies.get("refresh-token");
        if (token && refreshToken) {
            const jwtToken: JwtToken = { token, refreshToken };
            startRefresh(jwtToken);
        }
    };

    const logout = (reload?: boolean) => {
        cookies.remove("token", { path: "/" });
        cookies.remove("refresh-token", { path: "/" });
        cookies.remove("x-id-provider", { path: "/" });
        cookies.remove("bb-profile-picture", { path: "/" });
        if (refreshTokenInterval.current) {
            clearInterval(refreshTokenInterval.current);
        }
        setUser(undefined);
        refreshTokenInterval.current = undefined;
        if (reload) {
            window.location.reload();
        }
    };

    const refreshToken = (token?: JwtToken) => {
        const cookieToken = getRefreshToken();
        if (token || cookieToken) {
            authApiClient
                .refresh(token?.refreshToken ?? cookieToken)
                .then((jwt: JwtToken) => {
                    login(jwt);
                })
                .catch(() => logout());
        }
    };

    const startRefresh = (token: JwtToken) => {
        if (refreshTokenInterval.current) {
            clearInterval(refreshTokenInterval.current);
        }
        refreshTokenInterval.current = setInterval(
            () => refreshToken(token),
            EXPIRY_TIME - EXPIRY_OFFSET
        );
    };

    const isLoggedIn = (): boolean => {
        const jwtToken = getToken();
        return !!(user && jwtToken);
    };

    const login = (jwtToken: JwtToken) => {
        setToken(jwtToken.token);
        setRefreshToken(jwtToken.refreshToken);
        setIdProvider(IdProvider.SPARKYETL)
        // setCookiesFromToken(jwtToken);
        startRefresh(jwtToken);
    };
    
    const loginGoogle = (googleToken: string, profilePicture?: string) => {
        setToken(googleToken)
        setIdProvider(IdProvider.GOOGLE)
        setProfilePicture(profilePicture || "")
        setRefreshToken("")
    }
    
    const loginFacebook = (facebookToken: string, profilePicture?: string) => {
        setToken(facebookToken)
        setIdProvider(IdProvider.FACEBOOK)
        setProfilePicture(profilePicture || "")
        setRefreshToken("")
    }
    
    const setCurrentUser = (user: User) => {
        setUser(user);
    };

    const verifyToken = async (): Promise<boolean> => {
        if (!user) {
            return false;
        }
        return authApiClient
            .isAuthorized()
            .then(() => true)
            .catch(async () => {
                //if we run into any issue, we want to try to refresh the token, otherwise invalidate the user context and jwt token
                const refreshToken = getRefreshToken();
                if (refreshToken) {
                    await authApiClient
                        .refresh(refreshToken)
                        .then((jwt: JwtToken) => {
                            login(jwt);
                        })
                        .catch(() => logout());
                    return getToken() !== undefined;
                } else {
                    logout();
                    return false;
                }
            });
    };

    const userHasAccess = (...roles: Role[]): boolean => {
        return checkAccessforUser(user, roles);
    };

    const userCtx: UserContext = {
        user: user,
        setTokenFromCookies,
        isLoggedIn,
        login,
        loginGoogle,
        loginFacebook,
        logout,
        setCurrentUser,
        verifyToken,
        userHasAccess,
        refreshToken,
    };

    return (
        <>
            <UserContextHolder.Provider value={userCtx}>
                <GoogleOAuthProvider clientId="488982936701-aah25ek8b5utbomvslgmhcmmqvmir7ij.apps.googleusercontent.com">
                    {props.children}
                </GoogleOAuthProvider>
            </UserContextHolder.Provider>
        </>
    );
};
