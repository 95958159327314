import { NodeModel, NodeModelGenerics, PortModelAlignment } from '@projectstorm/react-diagrams';
import { SparkyELTPortModel } from '../../port/SparkyELTPortModel';

export interface DiamondNodeModelGenerics {
    PORT: SparkyELTPortModel;
}

export class RemoveColNodeModel extends NodeModel<NodeModelGenerics & DiamondNodeModelGenerics> {

    inputColumns: string[];
    outputColumns: string[];
    removedColumns: string[];
    comment: string;

    constructor() {
        super({
            type: 'Node_REMOVE_COL'
        });
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'input'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output'));
        this.inputColumns = []
        this.outputColumns = []
        this.removedColumns = []
        this.comment = "";
    }

    calculateOutputCols(): string[] {

        const outCols: string[] = []
        this.inputColumns.forEach((colName: string, idx: number) => {

            if (!this.removedColumns.includes(colName)) {
                outCols.push(colName)
            }
        })
        this.setOutputColumns(outCols);
        return outCols
    }

    // Override the serialize method to include custom properties
    serialize(): any {
        return {
            ...super.serialize(),
            removedColumns: this.removedColumns,
            comment: this.comment
        };
    }

    // Override the deserialize method to restore custom properties
    deserialize(event: any): void {
        super.deserialize(event);
        this.removedColumns = event.data.removedColumns;
        this.comment = event.data.comment;
    }

    getInputColumns(): string[] {
        return this.inputColumns
    }

    setInputColumns(cols: string[]): void {
        this.inputColumns = cols
    }

    getOutputColumns(): string[] {
        return this.outputColumns
    }

    setOutputColumns(cols: string[]): void {
        this.outputColumns = cols
    }

    getRemovedColumns(): string[] {
        return this.removedColumns
    }

    setRemovedColumns(cols: string[]): void {
        this.removedColumns = cols
    }

    getComment(): string {
        return this.comment
    }

    setComment(comment: string): void {
        this.comment = comment
    }

}