import { AddColNodeWidget } from './AddColNodeWidget';
import { AddColNodeModel } from './AddColNodeModel';
import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import {GenerateModelEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory";
import {GenerateWidgetEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractReactFactory";

export class AddColNodeFactory extends AbstractReactFactory<AddColNodeModel, DiagramEngine> {
    constructor() {
        super('Node_ADD_COL');
    }

    generateReactWidget(event: GenerateWidgetEvent<any>): JSX.Element {
        return <AddColNodeWidget engine={this.engine} width={303} height={179} node={event.model} />;
    }

    generateModel(event: GenerateModelEvent) {
        return new AddColNodeModel();
    }
}