import { NodeModel, NodeModelGenerics, PortModelAlignment } from '@projectstorm/react-diagrams';
import { SparkyELTPortModel } from '../../port/SparkyELTPortModel';

export interface DiamondNodeModelGenerics {
    PORT: SparkyELTPortModel;
}

export class FilterNodeModel extends NodeModel<NodeModelGenerics & DiamondNodeModelGenerics> {

    inputColumns: string[];
    outputColumns: string[];
    comment: string;

    constructor() {
        super({
            type: 'Node_FILTER'
        });
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'input'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, 'outputTop'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, 'outputBottom'));

        this.inputColumns = []
        this.outputColumns = []
        this.comment = "";
    }

    // Override the serialize method to include custom properties
    serialize(): any {
        return {
            ...super.serialize(),
            comment: this.comment
        };
    }

    // Override the deserialize method to restore custom properties
    deserialize(event: any): void {
        super.deserialize(event);
        this.comment = event.data.comment;
    }

    getInputColumns(): string[] {
        return this.inputColumns
    }

    setInputColumns(cols: string[]): void {
        this.inputColumns = cols
    }

    getOutputColumns(): string[] {
        return this.outputColumns
    }

    setOutputColumns(cols: string[]): void {
        this.outputColumns = cols
    }

    getComment(): string {
        return this.comment
    }

    setComment(comment: string): void {
        this.comment = comment
    }


}