import { NodeModel, NodeModelGenerics, PortModelAlignment } from '@projectstorm/react-diagrams';
import { SparkyELTPortModel } from '../../port/SparkyELTPortModel';

export interface DiamondNodeModelGenerics {
    PORT: SparkyELTPortModel;
}

export class WriteNodeModel extends NodeModel<NodeModelGenerics & DiamondNodeModelGenerics> {

    selectedConnectionId: string;
    selectedTargetElement: string;
    inputColumns: string[];
    comment: string;

    constructor() {
        super({
            type: 'Node_WRITE'
        });
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'input'));

        this.selectedConnectionId = "";
        this.selectedTargetElement = "";
        this.comment = "";
        this.inputColumns = []
    }

    // Override the serialize method to include custom properties
    serialize(): any {
        return {
            ...super.serialize(),
            selectedConnectionId: this.selectedConnectionId,
            selectedTargetElement: this.selectedTargetElement,
            comment: this.comment
        };
    }

    // Override the deserialize method to restore custom properties
    deserialize(event: any): void {
        super.deserialize(event);
        this.selectedConnectionId = event.data.selectedConnectionId;
        this.selectedTargetElement = event.data.selectedTargetElement;
        this.comment = event.data.comment;
    }

    calculateOutputCols(): string[] {
        // todo: implement here. At the moment done in Demo.tsx
        return [];
    }

    getSelectedConnectionId(): string {
        return this.selectedConnectionId;
    }

    setSelectedConnectionId(value: string): void {
        this.selectedConnectionId = value;
    }

    getSelectedTargetElement(): string {
        return this.selectedTargetElement;
    }

    setSelectedTargetElement(value: string): void {
        this.selectedTargetElement = value;
    }

    getInputColumns(): string[] {
        return this.inputColumns
    }

    setInputColumns(cols: string[]): void {
        this.inputColumns = cols
    }

    getComment(): string {
        return this.comment
    }

    setComment(comment: string): void {
        this.comment = comment
    }
}