export enum NodeTypeEnum {
    NODE_READ = "NODE_READ",
    NODE_GROUP_BY = "NODE_GROUP_BY",
    NODE_FILL_NEW_COL = "NODE_FILL_NEW_COL",
    NODE_REMOVE_COL = "NODE_REMOVE_COL",
    NODE_FILTER = "NODE_FILTER",
    NODE_APPEND = "NODE_APPEND",
    NODE_MERGE = "NODE_MERGE",
    NODE_SORT = "NODE_SORT",
    NODE_RENAME_COL = "NODE_RENAME_COL",
    NODE_REORDER_COL = "NODE_REORDER_COL",
    NODE_REPLACE_VALUE = "NODE_REPLACE_VALUE",
    NODE_WRITE = "NODE_WRITE",
}

