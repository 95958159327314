import * as React from 'react';
import { ClusterStatus } from '../../../clients/model/Cluster';
import { DoNotDisturb, Pending } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';

export interface IClusterStatusIconProps {
    status?: ClusterStatus
}

const ClusterStatusIcon: React.FunctionComponent<IClusterStatusIconProps> = (props: IClusterStatusIconProps) => {
    switch (props.status?.state) {
        case undefined:
            return <Tooltip title="No cluster available.">
                <DoNotDisturb color="disabled" />
            </Tooltip>
        case "STARTING":
            return <Tooltip title="Cluster is starting.">
                <CircularProgress size="24px" color="info" />
            </Tooltip>
        case "WAITING":
            return <Tooltip title="Cluster is ready.">
                <Pending color="success" />
            </Tooltip>
        case "RUNNING":
            return <Tooltip title="Cluster is busy.">
            <CircularProgress size="24px" color="success" />
            </Tooltip>
        case "TERMINATING":
            return <Tooltip title="Cluster is shutting down.">
                <CircularProgress size="24px" color="error" />
            </Tooltip>
        case "TERMINATED":
            return <Tooltip title="Cluster is offline.">
                <DoNotDisturb color="error" />
            </Tooltip>
        default:
            console.log(props.status?.state)
            return <Tooltip title={props.status?.state}>
                <span> {props.status?.state}</span>
            </Tooltip>
    }
}

export default ClusterStatusIcon;