import { AppBar, Avatar, Button, Grid, Menu, MenuItem, Toolbar } from '@mui/material';
import * as React from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import Logo from './Logo';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../contexts/UserContext';
import LanguageSwitcher from './LanguageSwitcher';
import LoginIcon from '@mui/icons-material/Login';

export interface IHeaderProps {
}

const Header: React.FunctionComponent<IHeaderProps> = (props: IHeaderProps) => {

    const { t } = useTranslation();
    const cookies = new Cookies()
    const navigate = useNavigate()
    const userCtx = useUserContext()


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose()
        userCtx.logout(true)
        navigate('/')
    }
    const handleProfile = () => {
        handleClose()
        navigate('/profile')
    }

    return (
        <>
            <AppBar position="sticky" sx={{ background: "#00000000", boxShadow: "none" }}>
                <Toolbar>
                    <Grid container alignItems="center">
                        <Grid item xs={6} >
                            <Button color="primary" variant="text" sx={{ width: "2em" }} component={NavLink} to={"/"} >
                                <Logo sx={{ width: "100%", height: "100%" }} />
                            </Button>
                            {userCtx.isLoggedIn() ? (<>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <Button color="primary" variant="text" sx={{ width: "2em" }} component={NavLink} to={"/dashboard"} >
                                    Dashboard
                                </Button>
                                </>
                            ) : <></>}
                        </Grid>
                        <Grid item xs={6} container justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Grid item>
                                <LanguageSwitcher />
                            </Grid>
                            <Grid item>
                                {userCtx.isLoggedIn() ? (
                                    <>
                                        <Avatar src={(cookies.get("bb-profile-picture") !== "" ? cookies.get("bb-profile-picture") : "")} sx={{ bgcolor: theme => theme.palette.primary.main, cursor: 'pointer' }}
                                            onClick={handleMenu}>
                                        </Avatar>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={handleProfile}>{t("userMenu.profile")}</MenuItem>
                                            <MenuItem onClick={handleClose}>{t("userMenu.settings")}</MenuItem>
                                            <MenuItem onClick={handleLogout}>{t("userMenu.logout")}</MenuItem>
                                        </Menu>
                                    </>
                                ) :
                                    <Button variant="contained"
                                        startIcon={<LoginIcon />}
                                        color='primary'
                                        component={NavLink}
                                        to="/login"
                                    >
                                        {t("userMenu.login")}
                                    </Button>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar >
        </>
    );
}

export default Header;