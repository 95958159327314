import { MergeNodeModel } from './MergeNodeModel';
import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import {GenerateModelEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory";
import {GenerateWidgetEvent} from "@projectstorm/react-canvas-core/dist/@types/core/AbstractReactFactory";
import MergeNodeWidget from "./MergeNodeWidget";

export class MergeNodeFactory extends AbstractReactFactory<MergeNodeModel, DiagramEngine> {
    constructor() {
        super('Node_MERGE');
    }

    generateReactWidget(event: GenerateWidgetEvent<any>): JSX.Element {
        return <MergeNodeWidget engine={this.engine} width={303} height={179} node={event.model} />;
    }

    generateModel(event: GenerateModelEvent) {
        return new MergeNodeModel();
    }
}