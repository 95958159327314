import { NodeModel, NodeModelGenerics, PortModelAlignment } from '@projectstorm/react-diagrams';
import { SparkyELTPortModel } from '../../port/SparkyELTPortModel';
import {DEFAULT_JOIN_TYPE_SELECTED} from "./settings/MergeNodeSettings";

export interface DiamondNodeModelGenerics {
    PORT: SparkyELTPortModel;
}

export class MergeNodeModel extends NodeModel<NodeModelGenerics & DiamondNodeModelGenerics> {

    inputColumnsTop: string[];
    inputColumnsBottom: string[];
    outputColumns: string[];
    joinFieldsTop: string[];
    joinFieldsBottom: string[];
    comment: string;
    selectedMergeTypeName: string;

    constructor() {
        super({
            type: 'Node_MERGE'
        });
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputTop'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputBottom'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output'));

        this.inputColumnsTop = []
        this.inputColumnsBottom = []
        this.outputColumns = []
        this.joinFieldsTop = []
        this.joinFieldsBottom = []
        this.comment = "";
        this.selectedMergeTypeName = DEFAULT_JOIN_TYPE_SELECTED;
    }

    // Override the serialize method to include custom properties
    serialize(): any {
        return {
            ...super.serialize(),
            joinFieldsTop: this.joinFieldsTop,
            joinFieldsBottom: this.joinFieldsBottom,
            selectedMergeTypeName: this.selectedMergeTypeName,
            comment: this.comment
        };
    }

    // Override the deserialize method to restore custom properties
    deserialize(event: any): void {
        super.deserialize(event);
        this.joinFieldsTop = event.data.joinFieldsTop;
        this.joinFieldsBottom = event.data.joinFieldsBottom;
        this.selectedMergeTypeName = event.data.selectedMergeTypeName;
        this.comment = event.data.comment;
    }
    calculateOutputCols(): string[] {
        const tmp = this.calculateOutputCols_full()
        return tmp.withPrefixes
    }

    calculateOutputCols_full(): { withPrefixes: string[], withoutPrefixes: string[] }  {

        // Helper function to generate unique column names
        const generateUniqueName = (baseName: string, prefix: string, existingNames: Set<string>): string => {
            let uniqueName = `${prefix}${baseName}`;
            let counter = 1;
            while (existingNames.has(uniqueName)) {
                uniqueName = `${prefix}${baseName}_${counter}`;
                counter++;
            }
            return uniqueName;
        };

        const resultColsWithPrefixes: string[] = [];
        const resultColsWithoutPrefixes: string[] = [];
        const existingNames: Set<string> = new Set();

        // Add join columns first (from joinFields1)
        this.joinFieldsTop.forEach((field, index) => {
            let baseName = this.joinFieldsBottom[index] ? `${field}_${this.joinFieldsBottom[index]}` : field;
            if (this.joinFieldsBottom[index] && this.joinFieldsBottom[index] === field) {
                baseName = field
            }
            const uniqueName = generateUniqueName(baseName, "", existingNames);
            resultColsWithPrefixes.push("m_" + uniqueName);
            resultColsWithoutPrefixes.push(uniqueName);
            existingNames.add(uniqueName);
        });

        // Add remaining columns from the first input file with prefix "T_"
        this.inputColumnsTop.forEach(col => {
            if (!this.joinFieldsTop.includes(col)) {
                const uniqueName = generateUniqueName(col, "", existingNames);
                resultColsWithPrefixes.push("T_" + uniqueName);
                resultColsWithoutPrefixes.push(uniqueName);
                existingNames.add(uniqueName);
            }
        });

        // Add remaining columns from the second input file with prefix "B_"
        this.inputColumnsBottom.forEach(col => {
            if (!this.joinFieldsBottom.includes(col)) {
                const uniqueName = generateUniqueName(col, "", existingNames);
                resultColsWithPrefixes.push("B_" + uniqueName);
                resultColsWithoutPrefixes.push(uniqueName);
                existingNames.add(uniqueName);
            }
        });
        return { withPrefixes: resultColsWithPrefixes, withoutPrefixes: resultColsWithoutPrefixes };
    }

    getInputColumnsTop(): string[] {
        return this.inputColumnsTop
    }

    setInputColumnsTop(cols: string[]): void {
        this.inputColumnsTop = cols
    }

    getInputColumnsBottom(): string[] {
        return this.inputColumnsBottom
    }

    setInputColumnsBottom(cols: string[]): void {
        this.inputColumnsBottom = cols
    }

    getOutputColumns(): string[] {
        return this.outputColumns
    }

    setOutputColumns(cols: string[]): void {
        this.outputColumns = cols
    }

    getJoinFieldsTop(): string[] {
        return this.joinFieldsTop
    }

    setJoinFieldsTop(cols: string[]): void {
        this.joinFieldsTop = cols
    }

    getJoinFieldsBottom(): string[] {
        return this.joinFieldsBottom
    }

    setJoinFieldsBottom(cols: string[]): void {
        this.joinFieldsBottom = cols
    }

    getSelectedMergeTypeName(): string {
        return this.selectedMergeTypeName
    }

    setSelectedMergeTypeName(selectedMergeTypeName: string): void {
        this.selectedMergeTypeName = selectedMergeTypeName
    }

    getComment(): string {
        return this.comment
    }

    setComment(comment: string): void {
        this.comment = comment
    }
}