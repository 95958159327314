import { Box, Typography, Grid } from '@mui/material';
import * as React from 'react';

export interface IFooterProps {
}

const Footer: React.FunctionComponent<IFooterProps> = (props: IFooterProps) => {
    return (
        <>
            <Box sx={{
                position: "sticky",
                width: "auto",
                marginTop: "auto",
                left: 0,
                right: 0,
                background: theme => theme.palette.secondary.main,
                paddingTop: "0.25em",
                paddingBottom: "0.25em",
                paddingLeft: "1em",
                paddingRight: "1em",
            }}>
                <Grid container>
                    <Grid item xs={12} container justifyContent="center" alignContent="center">
                        <Typography variant="body2" align='center' textAlign="center">
                            Sparky ist ein freier Elf!
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={6} container justifyContent="right" alignContent="center">
                    <Button color="secondary" size='small' variant="text" component={Link} to="/impressum">Impressum</Button>
                </Grid> */}
                </Grid>
            </Box >
        </>
    );
}

export default Footer;