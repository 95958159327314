import { Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';

export interface IHeadlineProps {
    showLogo?: boolean
    title?: string
    size?: 'normal' | 'sub' | 'section'
}

const Headline: React.FunctionComponent<IHeadlineProps> = (props: IHeadlineProps) => {

    const settings = useMemo(() => {
        switch (props.size) {
            case 'sub':
                return {
                    centered: false,
                    fontSize: "30px",
                    fontWeight: 600
                }
            case 'section':
                return {
                    centered: false,
                    fontSize: "20px",
                    fontWeight: 400
                }
            case 'normal':
            default:
                return {
                    centered: true,
                    fontSize: "48px",
                    fontWeight: 700,
                    paddingBottom: '15px'
                }
        }
    }, [props.size])

    return (
        <>
            <Grid container spacing={3}>
                {props.showLogo && <Grid item xs={12} container justifyContent='center'>
                    <Button component={Link} to="/">
                    </Button>
                </Grid>}
                {props.title && <Grid item xs={12} container justifyContent={settings.centered ? 'center' : 'flex-start'}>
                    <Typography fontSize={settings.fontSize} color="error" fontWeight={700} sx={{
                        paddingBottom: settings.paddingBottom,
                    }}>
                        {props.title}
                    </Typography>
                </Grid>}
            </Grid >
        </>
    );
}

export default Headline;