import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useClusterContext } from '../../../contexts/ClusterContext';
import { InfoOutlined } from '@mui/icons-material';

export interface IClusterLogsProps {
}

const ClusterLogs: React.FunctionComponent<IClusterLogsProps> = (props: IClusterLogsProps) => {
    const [tab, setTab] = useState<'cluster' | 'spark'>('cluster')

    const clusterCtx = useClusterContext()


    useEffect(() => {
        if (tab === 'cluster') {
            clusterCtx.clusterLogsViewed()
        } else {
            clusterCtx.sparkLogsViewed()
        }
    }, [clusterCtx, tab])

    const handleTabChange = (_: React.SyntheticEvent, newValue: 'cluster' | 'spark') => {
        setTab(newValue)
    }

    return (
        <>
            <Typography align='center' variant="body1">
                <i>Hint: Logs may only be available a few minutes after execution of the stream.</i>
            </Typography>
            <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        centered
                        onChange={handleTabChange} aria-label="log_tabs"
                    >
                        <Tab label="Cluster Logs" iconPosition='end' icon={clusterCtx.clusterLogsChanged ? <InfoOutlined color='error' /> : <></>} value="cluster" />
                        <Tab label="Spark Logs" icon={clusterCtx.sparkLogsChanged ? <InfoOutlined color='error' /> : <></>} value="spark" />
                    </TabList>
                </Box>
                <TabPanel value="cluster">
                    {
                        (clusterCtx.clusterLogs?.length ?? 0) > 0 ?
                            <textarea
                                style={{ width: '100%', height: '50vh' }}
                                value={clusterCtx.clusterLogs}
                            />
                            : <Typography align='center' variant="body1" >
                                <i>No logs available</i>
                            </Typography>
                    }
                </TabPanel>
                <TabPanel value="spark">
                    {
                        (clusterCtx.sparkLogs?.length ?? 0) > 0 ?
                            <textarea
                                style={{ width: '100%', height: '50vh' }}
                                value={clusterCtx.sparkLogs}
                            />
                            : <Typography align='center' variant="body1">
                                <i>No logs available</i>
                            </Typography>
                    }
                </TabPanel>
            </TabContext>
        </>
    );
}

export default ClusterLogs;