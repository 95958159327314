import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Breadcrumbs, Link, Button, Tooltip, Fab, TextField, ClickAwayListener, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { FiFolder } from "react-icons/fi";
import { useTheme } from "@mui/material/styles";
import WhatsHot from "@mui/icons-material/Whatshot";
import { CreateNewFolder, Delete, Download, Home, InsertDriveFile, Sos, Upload } from '@mui/icons-material';
import UploadFileModal from './UploadFileModal';
import ConfirmDialog from '../dialogs/ConfirmDialog';


export type ExplorerItem = FolderItem | FileItem | StreamItem

export interface FolderItem {
    type: 'folder'
    path: string
    name: string
    children?: ExplorerItem[]
}

export interface FileItem {
    type: 'file'
    path: string
    name: string
    size: number
    key: string
    downloadLink?: string
}
export interface StreamItem {
    type: 'stream'
    name: string
    id: string
}


export interface ExplorerProps {
    data: ExplorerItem[]

    enableFolderCreation?: boolean
    enableFileCreation?: boolean
    enableStreamCreation?: boolean

    enableItemDeletion?: boolean

    onNewFolder?(folder: FolderItem): void

    onStructureChange?(): void

    onItemDeletion?(item: ExplorerItem): void

    onFileClick?(item: FileItem): void
    onStreamClick?(item: StreamItem): void
    onFolderClick?(item: FolderItem): void
    onItemClick?(item: ExplorerItem): void
}

const Explorer: React.FC<ExplorerProps> = (props: ExplorerProps) => {
    const [currentPath, setCurrentPath] = useState<FolderItem[]>([]);
    const [files, setFiles] = useState<ExplorerItem[]>(props.data);

    const [uploadOpen, setUploadOpen] = useState(false)

    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
        clickedItem: ExplorerItem;
    }>();

    const [deletionTarget, setDeletionTarget] = useState<{
        itemToDelete: ExplorerItem;
    }>();

    const closeContextMenu = () => setContextMenu(undefined)
    const handleContextMenu = (event: React.MouseEvent, item: ExplorerItem) => {
        event.preventDefault();
        setContextMenu(
            !contextMenu
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                    clickedItem: item
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                undefined,
        );
    };

    const [draftItem, setDraftItem] = useState<ExplorerItem>()

    const theme = useTheme();

    const handleFolderRedirect = (folder: FolderItem) => {
        setCurrentPath(prev => [...prev, folder]);
    };

    useEffect(()=>{
        const folder = currentPath.at(-1) ?? {children: props.data}
        setFiles(folder.children ?? []);
    },[currentPath, props.data])

    useEffect(() => {
        // update files in place when props.data changes
        let openFiles = props.data

        setCurrentPath(currentPath => {
            const newPath: FolderItem[] = []
            currentPath.forEach(folder => {
                const updatedFolder = props.data.find(f => f.type === 'folder' && f.path === folder.path)
                if (updatedFolder && updatedFolder.type === 'folder') {
                    openFiles = updatedFolder.children ?? []
                    newPath.push(updatedFolder)
                }
            });
            return newPath
        })

        setFiles(openFiles)

    }, [props.data])

    const handleElementClick = (element: ExplorerItem) => {
        console.log('clicked', element)
        console.log(currentPath)
        props.onItemClick && props.onItemClick(element)

        switch (element.type) {
            case 'file':
                props.onFileClick && props.onFileClick(element)
                break;
            case 'folder':
                handleFolderRedirect(element)
                props.onFolderClick && props.onFolderClick(element)
                break;
            case 'stream':
                props.onStreamClick && props.onStreamClick(element)
                break;
        }
    }

    const initiateFolderCreation = () => {
        setDraftItem({
            type: 'folder',
            name: '',
            path: currentPath.at(-1)?.path ?? ''
        })

    }

    const navigateToPath = (index: number) => {
        if (index === -1) {
            setCurrentPath([]);
            setFiles(props.data); // Reset to initial data when navigating to "Home"
        } else {
            const newPath = currentPath.slice(0, index + 1);
            setCurrentPath(newPath);
            const children = newPath.at(-1)?.children;
            setFiles(children ?? []); // Use an empty array as a fallback if children is undefined
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={10}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Button component={Link} color="inherit" onClick={() => navigateToPath(-1)}>
                            <Home />
                        </Button>
                        {currentPath.map((item, index) => (
                            <Button
                                component={Link}
                                key={item.path}
                                sx={{ cursor: 'pointer' }}
                                underline="hover"
                                color="inherit"
                                onClick={() => navigateToPath(index)}
                            >
                                {item.name}
                            </Button>
                        ))}
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={2} container justifyContent='flex-end'>

                    <Grid item xs={12} container spacing={2} justifyContent="flex-end">
                        {props.enableFileCreation && <Grid item>
                            <Tooltip title="Upload new file">
                                <Fab
                                    onClick={() => setUploadOpen(true)}
                                    color="primary">
                                    <Upload />
                                </Fab>
                            </Tooltip>
                        </Grid>}
                        {props.enableFolderCreation &&
                            <Grid item>
                                <Tooltip title="Create new folder">
                                    <Fab
                                        color="primary"
                                        onClick={initiateFolderCreation}
                                    >
                                        <CreateNewFolder />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                </Grid>

                <Grid item xs={12} container spacing={2} style={{ padding: 16 }}>
                    {files.map((item) => (
                        <Grid item key={item.name + '_' + item.type} xs={2}>
                            <Paper elevation={2}
                                component="div"
                                style={{
                                    padding: 16,
                                    textAlign: 'center',
                                    borderRadius: '50%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                    backdropFilter: 'blur(3px)',
                                    boxShadow: '8px 8px 15px rgba(0, 0, 0, 0.2)',
                                    border: '1px solid rgba(255, 255, 255, 0.2)',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleElementClick(item)}
                                onContextMenu={(e) => handleContextMenu(e, item)}
                            >
                                <Grid container justifyContent="center" alignItems='center'>

                                    <Grid item xs={12}>
                                        {item.type === 'folder' ?
                                            <FiFolder style={{ fontSize: 100, color: theme.palette.primary.main }} height="180px" />
                                            : item.type === 'file' ?
                                                <InsertDriveFile style={{ fontSize: 100, color: theme.palette.primary.main }} height="180px" />
                                                : <WhatsHot sx={{ fontSize: 100, color: theme.palette.primary.main }} height="180px" />
                                        }
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Tooltip title={item.name}>
                                            <Typography
                                                noWrap
                                                variant="subtitle1"
                                                sx={{ color: theme.palette.primary.main }}>
                                                {item.name}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                    {draftItem && <Grid item key="draft-item" xs={2}>
                        <ClickAwayListener onClickAway={() => setDraftItem(undefined)}>
                            <Paper elevation={2}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        switch (draftItem.type) {
                                            case 'folder':
                                                props.onNewFolder && props.onNewFolder(draftItem)
                                        }
                                        setDraftItem(undefined)
                                    }
                                    if (e.key === 'Escape') {
                                        setDraftItem(undefined)
                                    }
                                }}
                                style={{
                                    padding: 16,
                                    textAlign: 'center',
                                    borderRadius: '50%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                    backdropFilter: 'blur(3px)',
                                    boxShadow: '8px 8px 15px rgba(0, 0, 0, 0.2)',
                                    border: '1px solid rgba(255, 255, 255, 0.2)',
                                }}
                            >
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        {draftItem.type === 'folder' ?
                                            <FiFolder style={{ fontSize: 100, color: theme.palette.primary.main }} height="180px" />
                                            : draftItem.type === 'file' ?
                                                <InsertDriveFile style={{ fontSize: 100, color: theme.palette.primary.main }} height="180px" />
                                                : <WhatsHot sx={{ fontSize: 100, color: theme.palette.primary.main }} height="180px" />
                                        }</Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            fullWidth
                                            value={draftItem.name}
                                            autoFocus
                                            onChange={(e) => {
                                                const name = e.target.value
                                                setDraftItem(draft => draft && { ...draft, name })
                                            }}
                                            variant="standard"
                                            sx={{ color: theme.palette.primary.main }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </ClickAwayListener>
                    </Grid>}
                    {files.length === 0 && <Grid item xs={12}><Typography align="center" variant='body1'><i>No files or folders</i></Typography></Grid>}
                </Grid >
            </Grid >
            <UploadFileModal open={uploadOpen} onClose={() => {
                setUploadOpen(false)
                props.onStructureChange && props.onStructureChange()
            }} path={currentPath.at(-1)?.path ?? ''} />
            {contextMenu &&
                <Menu
                    open={true}
                    onClose={closeContextMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: contextMenu.mouseY, left: contextMenu.mouseX }}
                >
                    {contextMenu?.clickedItem.type === 'file' &&
                        <MenuItem onClick={() => {
                            if (contextMenu.clickedItem.type === 'file' && contextMenu.clickedItem.downloadLink) {
                                window.open(contextMenu.clickedItem.downloadLink, '_blank')
                            }
                            closeContextMenu()
                        }}>
                            <ListItemIcon>
                                <Download fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Download file</Typography>
                        </MenuItem>
                    }
                    {
                        props.enableItemDeletion &&
                        <MenuItem onClick={() => {
                            setDeletionTarget({ itemToDelete: contextMenu.clickedItem })
                            closeContextMenu()
                        }}>
                            <ListItemIcon>
                                <Delete fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Delete {contextMenu.clickedItem.type} '{contextMenu.clickedItem.name}'</Typography>
                        </MenuItem>
                    }
                    {contextMenu.clickedItem.type !== 'file' && !props.enableItemDeletion &&
                        <MenuItem onClick={() => {
                            closeContextMenu()
                        }}>
                            <ListItemIcon>
                                <Sos fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">You don't have any options</Typography>
                        </MenuItem>
                    }
                </Menu>
            }

            {deletionTarget &&
                <ConfirmDialog
                    open={true}
                    onConfirm={() => {
                        props.onItemDeletion && props.onItemDeletion(deletionTarget.itemToDelete)
                        setDeletionTarget(undefined)
                    }}
                    onClose={() => setDeletionTarget(undefined)}
                    confirmQuestion={`Delete ${deletionTarget.itemToDelete.type}?`}
                    severity='error'
                />}
        </>
    );
}

export default Explorer;