import { LinkModel, PortModel, DefaultLinkModel, PortModelAlignment } from '@projectstorm/react-diagrams';

export class SparkyELTPortModel extends PortModel {
    name: string = "";

    constructor(alignment: PortModelAlignment, name: string) {
        super({
            type: 'diamond',
            name: name,
            alignment: alignment
        });
        this.name = name;
    }

    createLinkModel(): LinkModel {
        return new DefaultLinkModel();
    }

    serialize(): any {
        return {
            ...super.serialize(),
            name: this.name
        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        // debugger;  // Inspect the deserialization process
        this.name = event.data.name;
    }
}