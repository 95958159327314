import * as React from 'react';
import ClusterLogs from './ClusterLogs';
import { Dialog, DialogContent } from '@mui/material';
import DialogHeader from '../../../components/dialogs/DialogHeader';

export interface IClusterLogDialogProps {
    open: boolean
    onClose(): void
}

const ClusterLogDialog: React.FunctionComponent<IClusterLogDialogProps> = (props: IClusterLogDialogProps) => {
    return (
        <>
        <Dialog open={props.open} onClose={props.onClose} PaperProps={{ sx: { height: "80vh" } }} maxWidth="xl" fullWidth>
                <DialogHeader
                    closeDialog={() => props.onClose()}
                    headline={'Cluster logs'}
                />
                <DialogContent dividers>
                   <ClusterLogs />
                </DialogContent>
            </Dialog >
        </>
    );
}

export default ClusterLogDialog;